import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
const httpOptions =  {
  headers : new HttpHeaders({'Content-type' : 'application/json'})
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //private hostname = location.hostname;
  baseUrl = "https://thecorpwork.com/dist/browser/assets/server/";
  user = '';
  token = '';
  checkUserExist = '';
  constructor(private http: HttpClient) { }

  public login(user: User):Observable<any>{
    console.log(this.baseUrl);
    //console.log(user);
    //console.log(this.baseUrl+'api.php');
    return this.http.post<any>(this.baseUrl+'api.php', user, httpOptions).pipe(map(user => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('token', user.token);
      localStorage.setItem('isloggedin', 'true');
      return user;
    }));
    
  }

  public isLoggedIn() {
    this.user = localStorage.getItem('currentUser');
    this.token = localStorage.getItem('token');
    this.checkUserExist = localStorage.getItem('isloggedin');
    if ((this.user != '' && this.token != '' && this.checkUserExist != '') && (this.user != null && this.token != null && this.checkUserExist != null)) {
        //console.log("user exist");
      return true;

    } else {
      //console.log("user does not exist");
      return false;
    }
  }

  public logout() {
      localStorage.setItem('currentUser', '');
      localStorage.setItem('token', '');
      localStorage.setItem('isloggedin', '');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.removeItem('isloggedin');
      return true;
  }
}
