import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  loginForm : FormGroup;
  loading = false;
  iserror = false;
  errorMessage = '';
  submitted = false;
  islogin = false;
  constructor(
    private authservice : AuthenticationService,
    private formbuilder : FormBuilder,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formbuilder.group({
      username : ['', Validators.required],
      password : ['', Validators.required]
    })
  }
  get f(){
    return this.loginForm.controls;
  }

  onSubmit() {
    //console.log("comingwwwwwwww");
    //console.log(this.loginForm.value);
   
    if(this.loginForm.invalid){
      //console.log("coming");
      return;
    }
    this.authservice.login(this.loginForm.value).subscribe(data => {
      this.islogin = true;
      //console.log(data);
      this.router.navigate(['/dashboard']);
    },
    error => {
      this.islogin = false;
      this.iserror = true;
      this.errorMessage = "Please check your credentials";
      //console.log(error);
    }
    )
  }

}
