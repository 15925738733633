import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClientsService } from '../../services/clients.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactUs : FormGroup;
  errorMessageContact = '';
  iserrorContact = false;
  submitMessage = '';
  formData:any;
  isContactSubmitted = false;
  constructor(
    private clients : ClientsService,
    private formbuilder : FormBuilder,
    private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      this.titleService.setTitle("Contact us today | Rent a desk hyderabad |Work space near me");
      this.metaTagService.updateTag({name:'description',content:'Get a desk for rent or lease at hyderabad locations,flexible workspaces available for rent with 24*7 access. Contact us today to find your office space'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Contact us' });
      
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: '' },
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
     }

    ngOnInit() {
      console.log("coming to init");
      this.contactUs = this.formbuilder.group({
        name : ['', Validators.required],
        email : ['', Validators.required],
        msg_subject : ['', Validators.required],
        message : ['', Validators.required]
      })
    }

    get f(){
      return this.contactUs.controls;
    }

    
    submitContact() {
      this.isContactSubmitted = false;
      console.log(this.contactUs.value);
     
      if(this.contactUs.invalid){
        this.iserrorContact = true;
        this.errorMessageContact = "Please fill all the fields";
        return;
      } else {
        this.formData = {"name":this.contactUs.value.name,
                        "email":this.contactUs.value.email,
                        "subject":this.contactUs.value.msg_subject,
                        "message":this.contactUs.value.message
                        }
        this.clients.contactUs(this.formData).subscribe(data => {
          console.log(data);
          if(data.message != '') {
            this.isContactSubmitted = true;
            this.submitMessage = "Thank you for contacting us.."
            console.log(this.submitMessage);
            this.contactUs.reset();
          }
          
        },
        error => {
          console.log(error);
        })
      }
      }



}
