<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>The Place</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Place</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Floor Plans Area -->
<section class="floor-plans-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <!-- <div class="section-title">
            <h1>Corporate Office Spaces In Gachibowli</h1>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div> -->

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                         

                        <li id="ngcontent_head"><a>
                            <h1 style="font-size: 26px;color: #e3d5bf;">Corporate Office Spaces In Gachibowli</h1>
                         </a></li>
 
                    </ul>

                    <div class="tab_content">
                        

                      

                        <div class="tabs_item">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12 content">
                                    <div class="tabs_item_content">
                                         
                                        <p> </p>

                                        <ul class="features-list">
                                            <li>Floor No <span>3</span></li>
                                            <li>Rooms <span>4</span></li>
                                            <li>Total Area <span>311.50 sqft</span></li>
                                            <li>Bathroom <span>Yes</span></li>
                                            <li>Windows <span>4</span></li>
                                        </ul>

                                        <!-- <a href="/" class="default-btn">Schedule a Visit <span></span></a> -->
                                        <div class="btn-box">
                                            <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                                             Schedule A Visit
                                            </button>                           
                                            </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 image">
                                    
                                    <div class="about-image">
                     
                                        <div class="img1">
                                            <img src="assets/img/floor-img3.png" alt="image">
                                        </div> </div>
                                </div>
                            </div>
                        </div>

                      
 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Floor Plans Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <p>Gachibowli is one of the upcoming corporate hubs in Hyderabad. With the right combination of the corporate world, the fun and the entertainment elements, Gachibowli just makes it the right spot to plan your corporate office spaces, <a class="internallinks" href="/private-office-spaces">private office spaces</a>, coworking spaces, etc. As the growing popularity of the best office in Gachibowli increases so increases the demand and the search to find the right <a class="internallinks" href="/corporate-office-spaces">corporate office spaces</a> in Gachibowli.</p>
                    <p>To overcome the need for office spaces in  Gachibowli, Corpwork has made this easy and efficient for all the businesses, saas, enterprise companies to find and organize their offices.
                        Get world-class service, hospitality and fully equipped shared, private or team office spaces at Corpwork.</p>
                    <h5>Kickstart your business with the right setting!</h5>
                    <p>Give a perfect start to your emerging startup idea or your small business with the cozy, affordable, and professional workspaces in Gachibowli. Become a booming business with the right motivation and inspiration from the fully furnished and innovative office spaces as per your need. Get dedicated and continuous operational support from Corpwork while you achieve your startup dreams.</p>
                    <h5>Find your office fit with Corpwork
                    </h5>
                    <p>Get the quick, work-ready workspaces for a private office or coworking space, fully furnished and serviceable for your business with Corpwork. Have your dedicated workstations, 24x7 WiFi access, and spacious lounges and <a class="internallinks" href="/meeting-rooms">meeting rooms</a> for small to mid-level companies and teams. Eliminate stress and streamline your workspace with Corpwork corporate office spaces.</p>
                    <h5>Ace productive and flexibility</h5>
                    <p>Shared coworking spaces provide the environment to work more efficiently by connecting with peers granting to your own space. Uncover the right balance between productivity and personal space, with private office spaces or shared co-working spaces, that let you work in calm and ace in your job. Corpwork makes sure that you get to focus and get your things done, while we take the hassle of your office setup and administration.</p>
                    <h5>Build your brand in the right space</h5>
                    <p>A well-defined corporate office or workplace of business says a lot about the brand value in your marketplace. Provide a sophisticated and professional outlook of your corporate office spaces with Corpwork. With Corpwork transfer your office into stylish sleek professional spaces and get the right mark of your business!</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                
                <ngb-carousel  style="display: initial;"  >
               
<ng-template ngbSlide>
    <div class="picsum-img-wrapper">
                        <img src="assets/img/place-img2.jpg" alt="image">
                    </div>
                    </ng-template>
                    <ng-template ngbSlide>

                        <div class="picsum-img-wrapper">
                        <img src="assets/img/place-img3.jpg" alt="image">
                    </div>
                    </ng-template>
                    </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

        <!--
            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/7.png" alt="image">
                </a>
            </div>
            -->
      
    </div>
</section>
<!-- End Partner Area -->
