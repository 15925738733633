import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router ,NavigationEnd, NavigationStart} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication.service';
import { ClientsService } from './services/clients.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  bookMeeting : FormGroup;
  contactUs : FormGroup;
  formData : any;
  formDataContact : any;
  errorMessage = '';
  iserror = false;
  errorMessageContact = '';
  iserrorContact = false;
  tabSelected:any;
  title = 'thecorpwork';
  router:any;
  checkUser : any;
  userStatus :any;
  isBookingSubmited = false;
  isContactSubmitted = false;
  submitMessage = '';
  contactMessage = '';
  constructor(
    private titleService: Title, 
    // private authservice : AuthenticationService,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private doc,
    private _router: Router,
    private clients : ClientsService,
    private auth : AuthenticationService,
    private formbuilder : FormBuilder
    ) {
      this.router=_router
      this.router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationStart) {
            if (routerEvent.url == "/") {
                this.router.navigate(["home"], {skipLocationChange: true})
            }
        }
    });
     // const navEndEvent$ = _router.events.pipe(
     //   filter(e => e instanceof NavigationEnd)
     // );
      //navEndEvent$.subscribe((e: NavigationEnd) => {
       // gtag('config', 'UA-188676938-1');
      //});
     }

    ngOnInit() {

      this.bookMeeting = this.formbuilder.group({
        name : ['', Validators.required],
        email : ['', Validators.required],
        msg_subject : ['', Validators.required],
        message : ['', Validators.required]
      })
      this.contactUs = this.formbuilder.group({
        name : ['', Validators.required],
        email : ['', Validators.required],
        schudule_subject : ['', Validators.required],
        message : ['', Validators.required]
      })
      this.checkUser = this.auth.isLoggedIn();
      console.log(this.checkUser);
    }
    get f(){
      return this.bookMeeting.controls;
    }

    get c(){
      return this.contactUs.controls;
    }

  updateTabName(tab){
    this.tabSelected=tab;
    console.log(tab+"====")
    }
    logout() {
      localStorage.setItem('currentUser', '');
      localStorage.setItem('token', '');
      localStorage.setItem('isloggedin', '');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.removeItem('isloggedin');
      this.router.navigate(['/admin']);
    }

    bookMeetingNow() {
    console.log(this.bookMeeting.value);
    this.isBookingSubmited = false;;
    if(this.bookMeeting.invalid){
      this.iserror = true;
      this.errorMessage = "Please fill all the fields";
      return;
    } else{
      this.formData = {"name":this.bookMeeting.value.name,
                  "email":this.bookMeeting.value.email,
                  "subject":this.bookMeeting.value.msg_subject,
                  "message":this.bookMeeting.value.message
                }
      this.clients.bookMeetingRoom(this.bookMeeting.value).subscribe(data => {
        console.log(data);
       // if(data.message != '') {
        this.iserror = false;
          this.isBookingSubmited = true;
          this.submitMessage = "Thank you for contacting us. We will let you know your booking status"
          console.log(this.submitMessage);
          this.bookMeeting.reset();
      //  }
        this.router.navigate(['/']);
      },
      error => {
        console.log(error);
      })
    }
    }

    closeBooking() {
      //console.log("qqqq");
      this.bookMeeting.reset();
    }
    submitSchuduleTour() {
      console.log(this.contactUs.value);
      this.isContactSubmitted = false;;
    if(this.contactUs.invalid){
      this.iserrorContact = true;
      this.errorMessageContact = "Please fill all the fields";
      return;
    } else{
      this.formDataContact = {"name":this.contactUs.value.name,
                  "email":this.contactUs.value.email,
                  "subject":this.contactUs.value.schudule_subject,
                  "message":this.contactUs.value.message
                }
      this.clients.schuduleTourVisit(this.contactUs.value).subscribe(data => {
        console.log(data);
       // if(data.message != '') {
        this.iserrorContact = false;
          this.isContactSubmitted = true;
          this.contactMessage = "Thank you for contacting us. We will let you know your schudule status"
          console.log(this.contactMessage);
          this.contactUs.reset();
      //  }
        this.router.navigate(['/']);
      },
      error => {
        console.log(error);
      })
    }
    }


  }
