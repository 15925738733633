import { NgModule } from '@angular/core';
import { Routes, RouterModule,ExtraOptions  } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './components/about/about.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContactComponent } from './components/contact/contact.component';
import { CorporateOfficeSpacesInGachibowliComponent } from './components/corporate-office-spaces-in-gachibowli/corporate-office-spaces-in-gachibowli.component';
import { CorporateOfficeSpacesInHitechCityComponent } from './components/corporate-office-spaces-in-hitech-city/corporate-office-spaces-in-hitech-city.component';
import { CoworkingSpacesInKondapurComponent } from './components/coworking-spaces-in-kondapur/coworking-spaces-in-kondapur.component';
import { CoworkingSpacesInKphbComponent } from './components/coworking-spaces-in-kphb/coworking-spaces-in-kphb.component';
import { DedicatedDesksComponent } from './components/dedicated-desks/dedicated-desks.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { MeetingRoomsComponent } from './components/meeting-rooms/meeting-rooms.component';
import { NewsComponent } from './components/news/news.component';
import { PrivateOfficeSpacesComponent } from './components/private-office-spaces/private-office-spaces.component';
import { PrivatePolicyComponent } from './components/private-policy/private-policy.component';
import { ServicesComponent } from './components/services/services.component';
import { VirtualOfficeSpacesComponent } from './components/virtual-office-spaces/virtual-office-spaces.component';
import { PackagesComponent } from './components/packages/packages.component';
import { CorporateOfficeSpacesComponent } from './components/corporate-office-spaces/corporate-office-spaces.component';
import { CoworkingSpacesComponent } from './components/coworking-spaces/coworking-spaces.component';
import { AdminComponent } from './admin/admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AuthGuard } from './guard/auth.guard';
import { DisplaymeetingroombookingsComponent } from './admin/displaymeetingroombookings/displaymeetingroombookings.component';
import { DisplaycontactsComponent } from './admin/displaycontacts/displaycontacts.component';
import { SchuduletourrequestsComponent } from './admin/schuduletourrequests/schuduletourrequests.component';
import { BalimiComponent } from './components/balimi/balimi.component';
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},  
  { path: 'home', component: HomeComponent},
  { path: 'about', component: AboutComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'contact', component: ContactComponent },   
  { path: 'corporate-office-spaces-in-gachibowli', component: CorporateOfficeSpacesInGachibowliComponent },
  { path: 'corporate-office-spaces-in-hitech-city', component: CorporateOfficeSpacesInHitechCityComponent },   
  { path: 'coworking-spaces-in-kondapur', component: CoworkingSpacesInKondapurComponent },
  { path: 'coworking-spaces-in-kphb', component: CoworkingSpacesInKphbComponent },
  { path: 'dedicated-desks', component: DedicatedDesksComponent },
  { path: 'faq', component: FaqComponent },       
  { path: 'meeting-rooms', component: MeetingRoomsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'packages', component: PackagesComponent },
  { path: 'private-office-spaces', component: PrivateOfficeSpacesComponent },
  { path: 'privacy-policy', component: PrivatePolicyComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'virtual-office-spaces', component: VirtualOfficeSpacesComponent },
  { path: 'corporate-office-spaces', component: CorporateOfficeSpacesComponent },
  { path: 'coworking-spaces', component: CoworkingSpacesComponent },
  { path: 'admin', component: AdminComponent },
  {path:'balimi', component:BalimiComponent},
  { path: 'dashboard', 
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  { path: 'meeting-room-requests', 
  component: DisplaymeetingroombookingsComponent,
  canActivate: [AuthGuard]
  },
  { path: 'contact-requests', 
  component: DisplaycontactsComponent,
  canActivate: [AuthGuard]
  },
  { path: 'Schedule-tour-requests', 
  component: SchuduletourrequestsComponent,
  canActivate: [AuthGuard]
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
