import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-displaycontacts',
  templateUrl: './displaycontacts.component.html',
  styleUrls: ['./displaycontacts.component.css']
})
export class DisplaycontactsComponent implements OnInit {
  contacts : any;
  checkContacts = false;
  constructor(private clients : ClientsService) { }

  async ngOnInit(){
    await this.clients.getContactRequests().pipe(map(res => {
      console.log(res);
      this.contacts = res;
      console.log("resulttttt");
      console.log(this.contacts);
      if(res != '') {
        this.checkContacts = true;
      }
      
    })).toPromise();
  }

}
