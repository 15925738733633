
        <!-- Start Page Title Area -->
        <div class="page-title-area item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Dashboard</h2>
                            <ul>
                                <li><a href="/">Admin</a></li>
                                <li>Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-title">
                            
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-text">
                            <p>Work better together! <br>

The rise of digital technology and the shared economy has changed the way we work forever. The demand for efficiency, agility and innovation is greater that it has ever been before.
The proliferation of coworking spaces globally shows that the way we work is changing. <br><br>

At CorpWork our model integrates and connects members into one community, while at the same time, providing them the option of working independently. We continually disrupt the status quo and redefine collaborative working. <br><br>

In our communities, we connect dynamic entrepreneurs, start-ups and large corporations. People are at the heart of what we do, as we create networking events and strategic partnerships for our members and partners to gain insights, collaborate, and help each other. Our passion for customer satisfaction ensures our members find the same hospitality – comfort and peace of mind – no matter where they are. We make work better by revolutionising how people work, empowering our ever-growing community with exceptional flexibility, opportunities and experiences.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Area -->

        