<div class="page-title-area item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog</h2>
                <h3 style="color:white">Coming Soon</h3>
                     
                </div>
            </div>
        </div>
    </div>
    </div>