 <!-- Start Page Title Area -->
 <div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1 style="color:aliceblue">News Grid</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>News Grid</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/1.webp" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Mar, 2021</div>
                    </div>

                    <div class="post-content">
                        <h3><a >The Case for Coworking in 2021</a></h3>
                        <p></p>

                        <a href="https://coworkinginsights.com/the-case-for-coworking-in-2021/" target="_blank" class="default-btn">Read More<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/2.webp" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Mar, 2021</div>
                    </div>

                    <div class="post-content">
                        <h3><a >How Coworking Spaces Are Getting Their Business Up & Running Again</a></h3>
                        <p></p>

                        <a href="https://www.deskmag.com/en/coworking-spaces/how-coworking-spaces-are-reopening-their-locations-operations-corona-covid19-virus" target="_blank" class="default-btn">Read More<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/3.webp" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Mar, 2021</div>
                    </div>

                    <div class="post-content">
                        <h3><a >What does coworking look like when the physical space is closed?</a></h3>
                        <p></p>

                        <a href="https://newworker.co/mag/what-does-coworking-look-like-when-the-physical-space-is-closed/" target="_blank" class="default-btn">Read More<span></span></a>
                    </div>
                </div>
            </div>

            
<!--
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/7.jpg" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Oct 14, 2019</div>
                    </div>

                    <div class="post-content">
                        <h3><a >5 Reasons Every Business Needs an Office Space</a></h3>
                        <p>Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <a  class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/8.jpg" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Oct 10, 2019</div>
                    </div>

                    <div class="post-content">
                        <h3><a >How to Choose the Best Coworking Space</a></h3>
                        <p>Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <a  class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a ><img src="assets/img/blog-image/9.jpg" alt="image"></a>

                        <div class="date"><i class="flaticon-calendar"></i> Sep 13, 2019</div>
                    </div>

                    <div class="post-content">
                        <h3><a >Counting the Cost of Your Office Space Choices</a></h3>
                        <p>Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <a  class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a  class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a  class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a  class="page-numbers">3</a>
                    <a  class="page-numbers">4</a>
                    <a  class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
            -->
        </div>
    </div>
</section>
<!-- End Blog Area -->