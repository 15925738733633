import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authservice : AuthenticationService
){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
   //console.log(localStorage.getItem('currentUser'));
   //console.log(localStorage.getItem('token'));
   //console.log(localStorage.getItem('isloggedin'));
     //   console.log("coming ti can");
    if (this.authservice.isLoggedIn()) {
       // console.log("coming to auth");
        return true;
      } else {
        //console.log("coming to not");
        this.router.navigate(['/admin']);
        return false;
      }
     
    
    
    
}
      
  }
  

