
        <!-- Start Page Title Area -->
        <div class="page-title-area item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Contact Requests</h2>
                            <ul>
                                <li><a href="/">Admin</a></li>
                                <li>Contact Requests</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-12">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th style="width:2px">S.No</th>
                                    <th style="width:25px">Name</th>
                                    <th style="width:50px">Email</th>
                                    <th style="width:150px">Subject</th>
                                    <th style="width:250px">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let contact of contacts | keyvalue;let i=index">
                                    <td style="width:2px">{{i+1}}</td> 
                                    <td style="width:25px">{{contact.value['name']}}</td>
                                    <td style="width:50px">{{contact.value['email']}}</td>
                                    <td style="width:150px">{{contact.value['subject']}}</td>
                                    <td style="width:250px">{{contact.value['message']}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    
                </div>
            </div>
        </section>
        <!-- End About Area -->

        