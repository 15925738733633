import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-displaymeetingroombookings',
  templateUrl: './displaymeetingroombookings.component.html',
  styleUrls: ['./displaymeetingroombookings.component.css']
})
export class DisplaymeetingroombookingsComponent implements OnInit {
  meetingBookings : any;
  checkBookings = false;
  constructor(private clients : ClientsService) { }

  async ngOnInit(){
    console.log("welcome to booking");
   await this.clients.getMeetingRoomRequests().pipe(map(res => {
      console.log(res);
      if(res != '') {
        this.checkBookings = true;
      }
      this.meetingBookings = res;
    })).toPromise();

  }

}
