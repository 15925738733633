import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { HashLocationStrategy, LocationStrategy ,PathLocationStrategy} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { NewsComponent } from './components/news/news.component';
import { PackagesComponent } from './components/packages/packages.component';
import { ServicesComponent } from './components/services/services.component';
import { MeetingRoomsComponent } from './components/meeting-rooms/meeting-rooms.component';
import { VirtualOfficeSpacesComponent } from './components/virtual-office-spaces/virtual-office-spaces.component';
import { DedicatedDesksComponent } from './components/dedicated-desks/dedicated-desks.component';
import { CoworkingSpacesInKphbComponent } from './components/coworking-spaces-in-kphb/coworking-spaces-in-kphb.component';
import { CorporateOfficeSpacesInHitechCityComponent } from './components/corporate-office-spaces-in-hitech-city/corporate-office-spaces-in-hitech-city.component';
import { CorporateOfficeSpacesInGachibowliComponent } from './components/corporate-office-spaces-in-gachibowli/corporate-office-spaces-in-gachibowli.component';
import { CoworkingSpacesInKondapurComponent } from './components/coworking-spaces-in-kondapur/coworking-spaces-in-kondapur.component';
import { PrivateOfficeSpacesComponent } from './components/private-office-spaces/private-office-spaces.component';
import { BlogComponent } from './components/blog/blog.component';
import { FaqComponent } from './components/faq/faq.component';
import { PrivatePolicyComponent } from './components/private-policy/private-policy.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CorporateOfficeSpacesComponent } from './components/corporate-office-spaces/corporate-office-spaces.component';
import { CoworkingSpacesComponent } from './components/coworking-spaces/coworking-spaces.component';
import { AdminComponent } from './admin/admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplaycontactsComponent } from './admin/displaycontacts/displaycontacts.component';
import { DisplaymeetingroombookingsComponent } from './admin/displaymeetingroombookings/displaymeetingroombookings.component';
import { SchuduletourrequestsComponent } from './admin/schuduletourrequests/schuduletourrequests.component';
import { BalimiComponent } from './components/balimi/balimi.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    NewsComponent,
    PackagesComponent,
    ServicesComponent,   
    MeetingRoomsComponent,
    VirtualOfficeSpacesComponent,
    DedicatedDesksComponent,
    CoworkingSpacesInKphbComponent,
    CorporateOfficeSpacesInHitechCityComponent,
    CorporateOfficeSpacesInGachibowliComponent,
    CoworkingSpacesInKondapurComponent,
    PrivateOfficeSpacesComponent,
    BlogComponent,
    FaqComponent,
    PrivatePolicyComponent,
    CorporateOfficeSpacesComponent,
    CoworkingSpacesComponent,
    AdminComponent,
    DashboardComponent,
    DisplaycontactsComponent,
    DisplaymeetingroombookingsComponent,
    SchuduletourrequestsComponent,
    BalimiComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'thecorpwork' }),
    AppRoutingModule,
    RouterModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
   
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
