<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two">
    <div class="zash-responsive-nav">
        <div class="container">
            <div class="zash-responsive-menu">
                <div class="logo">
                    <a href="/">
                        <img src="assets/img/logo.webp" alt="logo" style="height:40px">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="zash-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="/">
                    <img src="assets/img/logo.webp" alt="logo" style="padding-top:10px;padding-left:100px;height:90px">
                </a>
                <div *ngIf="!checkUser">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="/" (click)="updateTabName('home')" [ngClass]="{'active':(tabSelected==='home'),'':false}" class="nav-link">Home</a></li>
                        <li class="nav-item"><a routerLink="about" (click)="updateTabName('about')" [ngClass]="{'active':(tabSelected==='about'),'':false}" class="nav-link">About</a></li>
                        <li class="nav-item"><a  [ngClass]="{'active':(tabSelected==='corporate-office-spaces-in-hitech-city' || tabSelected==='coworking-spaces-in-kphb'
                            || tabSelected==='corporate-office-spaces-in-gachibowli' || tabSelected==='coworking-spaces-in-kondapur'),'':false}" class="nav-link">The Place <i class="flaticon-down-arrow"></i></a>
                          <ul class="dropdown-menu">           
                                  <li class="nav-item"><a routerLink="corporate-office-spaces-in-hitech-city" (click)="updateTabName('corporate-office-spaces-in-hitech-city')" [ngClass]="{'active':(tabSelected==='corporate-office-spaces-in-hitech-city'),'':false}" class="nav-link">Hitech City</a></li>
                                  <li class="nav-item"><a routerLink="coworking-spaces-in-kphb" (click)="updateTabName('coworking-spaces-in-kphb')" [ngClass]="{'active':(tabSelected==='coworking-spaces-in-kphb'),'':false}" class="nav-link">KPHB</a></li>
                                  <li class="nav-item"><a routerLink="corporate-office-spaces-in-gachibowli" (click)="updateTabName('corporate-office-spaces-in-gachibowli')" [ngClass]="{'active':(tabSelected==='corporate-office-spaces-in-gachibowli'),'':false}" class="nav-link">Gachibowli</a></li>
                                  
                           </ul>
                      </li>
                        <li class="nav-item"><a [ngClass]="{'active':(tabSelected==='corporate-office-spaces' || tabSelected==='coworking-spaces'
                                                   || tabSelected==='dedicated-desks' || tabSelected==='meeting-rooms' 
                                                   || tabSelected==='private-office-spaces' || tabSelected==='virtual-office-spaces'),'':false}" class="nav-link">Services <i class="flaticon-down-arrow"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="corporate-office-spaces" (click)="updateTabName('corporate-office-spaces')" [ngClass]="{'active':(tabSelected==='corporate-office-spaces'),'':false}" class="nav-link">Corporate Office Spaces</a></li>
                            <li class="nav-item"><a routerLink="coworking-spaces" (click)="updateTabName('coworking-spaces')" [ngClass]="{'active':(tabSelected==='coworking-spaces'),'':false}" class="nav-link">Corworking Spaces</a></li>
                            <li class="nav-item"><a routerLink="dedicated-desks" (click)="updateTabName('dedicated-desks')" [ngClass]="{'active':(tabSelected==='dedicated-desks'),'':false}" class="nav-link">Dedicated Desks</a></li>
                            <li class="nav-item"><a routerLink="meeting-rooms" (click)="updateTabName('meeting-rooms')" [ngClass]="{'active':(tabSelected==='meeting-rooms'),'':false}" class="nav-link">Meeting Rooms</a></li>
                            <li class="nav-item"><a routerLink="private-office-spaces" (click)="updateTabName('private-office-spaces')" [ngClass]="{'active':(tabSelected==='private-office-spaces'),'':false}" class="nav-link">Private Office Spaces</a></li>
                            <li class="nav-item"><a routerLink="virtual-office-spaces" (click)="updateTabName('virtual-office-spaces')" [ngClass]="{'active':(tabSelected==='virtual-office-spaces'),'':false}" class="nav-link">Virtual Office Spaces</a></li>
                               
                            </ul>
                        
                        </li>
                        <!--<li class="nav-item"><a routerLink="packages" (click)="updateTabName('packages')" [ngClass]="{'active':(tabSelected==='packages'),'':false}" class="nav-link">Packages</a></li>-->
                        <li class="nav-item"><a routerLink="news" (click)="updateTabName('news')" [ngClass]="{'active':(tabSelected==='news'),'':false}" class="nav-link">News</a></li>
                        <li class="nav-item"><a routerLink="contact" (click)="updateTabName('contact')" [ngClass]="{'active':(tabSelected==='contact'),'':false}" class="nav-link">Contact</a></li>
                         
                                             
                       
                    </ul>

                    <div class="others-option">
                        <div class="call-us">
                            <div class="icon">
                                <i class="flaticon-call"></i>
                            </div>
                            Call Us:
                            <a href="tel:+7032891053">+91 70328 91053</a>
                        </div>
<button type="button" (click)="closeBooking()" class="default-btn" style="font-size:12px;padding:7px" data-toggle="modal" data-target="#bookroom">
Book Meeting Room
</button>


           <!-- <a href="#bookroom" class="default-btn" style="font-size:12px;padding:7px"> Book Meeting Room1 <span></span></a> -->
                    </div>
                </div>
            </div>
            <div *ngIf="checkUser">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="dashboard" (click)="updateTabName('Dashboard')" [ngClass]="{'active':(tabSelected==='Dashboard'),'':false}" class="nav-link">Dashboard</a></li> 
                        <li class="nav-item"><a routerLink="meeting-room-requests" (click)="updateTabName('Meeting Room Requests')" [ngClass]="{'active':(tabSelected==='Meeting Room Requests'),'':false}" class="nav-link">Meeting Room Requests</a></li> 
                        <li class="nav-item"><a routerLink="contact-requests" (click)="updateTabName('Contact Requests')" [ngClass]="{'active':(tabSelected==='Contact Requests'),'':false}" class="nav-link">Contact Requests</a></li> 
                        <li class="nav-item"><a routerLink="Schedule-tour-requests" (click)="updateTabName('Schedule Tour Requests')" [ngClass]="{'active':(tabSelected==='Schedule Tour Requests'),'':false}" class="nav-link">Schedule Tour Requests</a></li> 
                    </ul>
            
                    <div style = "float:right" class="others-option">
                        <button  (click)="logout()" type="button" class="default-btn" style="font-size:12px;padding:7px">
                        Logout
                        </button>
                    </div>
                </div>
            </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
 

<router-outlet></router-outlet>

<!-- Start Footer Area -->
<footer class="footer-area">
     
  <div class="container">
<div class="subscribe-area">
    <div class="row align-items-center">
        <div class="col-lg-5 col-md-12">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>
               <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>-->
            </div>
        </div>

        <div class="col-lg-7 col-md-12">
            <div class="subscribe-form">
                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe Now <i class="flaticon-right-chevron"></i></button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
            <div class="logo">
                <a href="/"><img src="assets/img/logo.webp" alt="image" style="padding-left:35px;height:90px"></a>

                <p>CORPWORK is a professional high-quality shared office with a mission to combine modern coworking spaces and an emphasis on community.</p>
            </div>

            <ul class="social">
                <li><a href="https://www.facebook.com/thecorpwork"  target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a  href="https://twitter.com/thecorpwork" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/company/corpwork/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                <li><a href="https://www.instagram.com/thecorpwork/" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
            <h3>Quick Links</h3>

            <ul class="footer-quick-links">
                  <li><a href="/">Home</a></li>
               <!-- <li><a>Our Team</a></li>-->
                <li><a routerLink="about">About Us</a></li>
                <li><a routerLink="news">Latest News</a></li> 
                 <!--<li><a routerLink="services">Our Services</a></li> -->              
               <!-- <li><a routerLink="packages">Our Packages</a></li>-->
                <li><a routerLink="contact">Contact Us</a></li>
                <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                <!--<li><a routerLink="faq">FAQ</a></li>-->
                <!--<li><a routerLink="news">Blog</a></li>-->
            </ul>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
        <div class="single-footer-widget">
            <h3>Contact Info</h3>

            <ul class="footer-contact-info">
                <li><span>Location:</span> <br> CorpWork Hub<br>81 Jubilee Enclave , HITEC City <br>Hyderabad, Telangana 500081</li>
                 <li><span>Email:</span> <a  >space@thecorpwork.com</a></li>
                <li><span>Phone:</span> <a  >+91 70328 91053</a></li>
                
                <li><a href="https://www.google.com/maps/place/CorpWork+Hub/@17.4554076,78.3743109,17z/data=!3m1!4b1!4m5!3m4!1s0x3bcb9369b1d8efc7:0xf78da3d637a90b1b!8m2!3d17.4554025!4d78.3764996" target="_blank">View Location on GoogleMap</a></li>
            </ul>
        </div>
    </div>
</div>

<div class="copyright-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
           <p><i class="far fa-copyright"></i> 2022 All Rights Reserved by <a href="https://balimicorp.com/" target="_blank">BĀLIMICORP ENTERPRISE PRIVATE LIMITED</a></p>
        </div>

        <div class="col-lg-6 col-sm-6 col-md-6">
            <ul>
                <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                <li><a >Terms & Conditions</a></li>
            </ul>
        </div>
    </div>
</div>
</div>

</footer>

<!-- End Footer Area -->
<div class="go-top"><i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i></div>

 
		<!--Modal-->
		<div class="modal fade" id="bookroom" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Book Meeting Room</h5>
                  <button type="button" (click)="closeBooking()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                       <div class="contact-form">
                                      <form [formGroup]="bookMeeting" id="contactForm" (ngSubmit)="bookMeetingNow()">
                                          <div class="row">
                                              <div class="col-lg-12 col-md-6">
                                                  <div class="form-group">
                                                      <input type="text" formControlName="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-6">
                                                  <div class="form-group">
                                                      <input type="email" formControlName="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                  <div class="form-group">
                                                      <input type="text" formControlName="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                  <div class="form-group">
                                                      <textarea formControlName="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your brief requirement" placeholder="Your Requirement"></textarea>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                
                                                <div *ngIf="iserror" class="alert alert-danger">{{errorMessage}}</div>
                                                <div *ngIf="isBookingSubmited" class="alert alert-success">{{submitMessage}}</div>
                                                <div class="form-group">
                                                    <input type="submit" value="Submit Request" class="btn float-right login_btn">
                                                </div>
                                                  <div class="clearfix"></div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                                </div>
                 
              </div>
            </div>
          </div>
          
          <!--Modal 2-->
          
                  <div class="modal fade" id="scheduletour" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Schedule Tour</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                      <div class="contact-form">
                                      <form id="contactForm"  [formGroup]="contactUs" (ngSubmit)="submitSchuduleTour()">
                                          <div class="row">
                                              <div class="col-lg-12 col-md-6">
                                                  <div class="form-group">
                                                      <input type="text" formControlName="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-6">
                                                  <div class="form-group">
                                                      <input type="email" formControlName="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                  <div class="form-group">
                                                      <input type="text" formControlName="schudule_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                  <div class="form-group">
                                                      <textarea formControlName="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your preferred Dates and Time" placeholder="Your Preferred Schedule 
          Timings"></textarea>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
          
                                              <div class="col-lg-12 col-md-12">
                                                <div *ngIf="iserrorContact" class="alert alert-danger">{{errorMessageContact}}</div>
                                                <!-- <div *ngIf="iserror" class="alert alert-danger">{{errorMessage}}</div> -->
                                                <div *ngIf="isContactSubmitted" class="alert alert-success">{{contactMessage}}</div>
                                                <div class="form-group">
                                                    <input type="submit" value="Submit" class="btn float-right login_btn">
                                                </div>
                                                  <div class="clearfix"></div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                                </div>
                 
              </div>
            </div>
          </div>

 


          