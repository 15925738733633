import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const httpOptions =  {
  headers : new HttpHeaders({'Content-type' : 'application/json'})
}
@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  baseUrl = "https://thecorpwork.com/dist/browser/assets/server/";

  // baseUrl = "http://localhost/server/";
  constructor(private http: HttpClient) { }

  public bookMeetingRoom(meetingData):Observable<any>{
    console.log("meetingData");
    console.log(meetingData);
    return this.http.post<any>(this.baseUrl+'clients.php?action=bookMeetingInsert', meetingData, httpOptions).pipe(map(data => {
     console.log("coming to client service");
     console.log(data);
      return data;
    }))
  }

  public schuduleTourVisit(schuduleData):Observable<any>{
    console.log("meetingData");
    console.log(schuduleData);
    return this.http.post<any>(this.baseUrl+'clients.php?action=schuduleTourVisitInsert', schuduleData, httpOptions).pipe(map(data => {
     console.log("coming to client service");
     console.log(data);
      return data;
    }))
  }

  public contactUs(contactData):Observable<any>{
    return this.http.post<any>(this.baseUrl+'clients.php?action=contactUsInsert', contactData, httpOptions).pipe(map(data => {
      console.log(data);
      return data;
    }))
  }

  public getMeetingRoomRequests():Observable<any>{
    return this.http.get(this.baseUrl+'clients.php?action=getBookMeetings', httpOptions);
  }

  public getScheduleRequests():Observable<any>{
    return this.http.get(this.baseUrl+'clients.php?action=getschedules', httpOptions);
  }

  public getContactRequests():Observable<any>{
    return this.http.get(this.baseUrl+'clients.php?action=getContacts', httpOptions);
  } 

}
