    <!-- Start Main Banner Area -->
 <div class="home-section">
    <div class="home-slides owl-carousel owl-theme">
        <div class="main-banner item-bg5">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">Your corporate office space</span>
                            <h1 style="color:white;font-size:48px">Professional, Creative, Flexible, Scalable Workspace</h1>

                            <div class="btn-box">
                            <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                             Schedule A Tour
                            </button>
                                <!--<a  class="default-btn" style="font-size:14px;padding:7px">Schedule A Tour <span></span></a>-->
                                <!-- <a class="optional-btn" data-ilb2-video='{"controls":"controls", "autoplay":false, "sources":[{"src":"assets/img/video.m4v", "type":"video/mp4"}]}' data-imagelightbox="video"><i class="flaticon-play-button"></i> Watch Video</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-banner item-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">Community space for Entrepreneurs</span>
                            <h1 style="color:white;font-size:48px">WELCOME TO OUR PURPOSE-DRIVEN WORKSPACE</h1>

                            <div class="btn-box">
                                <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                                 Schedule A Tour
                                 </button>
                                <!--<a  class="default-btn" style="font-size:14px;padding:7px">Schedule A Tour <span></span></a>-->
                                <!-- <a class="optional-btn" data-ilb2-video='{"controls":"controls", "autoplay":false, "sources":[{"src":"assets/img/video.m4v", "type":"video/mp4"}]}' data-imagelightbox="video"><i class="flaticon-play-button"></i> Watch Video</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-banner item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">The Best Workspace STARTUPS</span>
                            <h1 style="color:white;font-size:48px">PLACEMAKER, INCUBATOR, ECOSYSTEM ACCELERATOR</h1>

                            <div class="btn-box">
                                <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                                   Schedule A Tour
                               </button>
                                <!--<a  class="default-btn" style="font-size:14px;padding:7px">Schedule A Tour <span></span></a>-->
                                <!-- <a class="optional-btn" data-ilb2-video='{"controls":"controls", "autoplay":false, "sources":[{"src":"assets/img/video.m4v", "type":"video/mp4"}]}' data-imagelightbox="video"><i class="flaticon-play-button"></i> Watch Video</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-footer">
        <div class="container-fluid p-0">
            <div class="row m-0 align-items-center">
                <div class="col-lg-5 col-md-12 p-0">
                    <div class="banner-video">
                        <a class="video-btn" data-ilb2-video='{"controls":"controls", "autoplay":true, "sources":[{"src":"assets/img/videonew.mp4", "type":"video/mp4"}]}' data-imagelightbox="video"><i class="flaticon-play-button-1"></i></a>
                        <span>Watch Video</span>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12 p-0">
                    <div class="banner-contact-info">
                        <ul>
                            <li>
                                <i class="flaticon-location"></i>
                                <span></span>
                                 INDIA<br>SINGAPORE<br>USA
                            </li>

                            <li>
                                <i class="flaticon-email"></i>
                                <span>Email Address</span>
                                <a href="mailto:space@thecorpwork.com">space@thecorpwork.com</a>
                                 
                            </li>

                            <li>
                                <i class="flaticon-calendar"></i>
                                <span>Bussiness Hours</span>
                                24/7
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Ens Main Banner Area -->
 

<!-- Start About Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="img1">
                        <img src="assets/img/about-image/1.jpg" alt="image">
                    </div>

                    <div class="img2">
                        <img src="assets/img/about-image/2.jpg" alt="image">
                    </div>

                    <div class="img3">
                        <img src="assets/img/about-image/3.jpg" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Professional, Creative, Flexible, Scalable Workspace</h2>
                    <p>We are a <a class="internallinks" href="/meeting-rooms">meeting place</a> for innovative minds brought together to re-think, re-create, and disrupt the waters in a variety of industries.</p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-work"></i>
                            </div>
                            <span>Trusted Partner</span>   
                        </li> 
                        <li>
                            <div class="icon">
                                <i class="flaticon-target"></i>
                            </div>
                            <span>Workspace Solutions</span>   
                        </li>
                    </ul>
                    <a href="about" class="read-more-btn">More About Us <i class="flaticon-next"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->



<!-- Start Services Area -->
<section class="services-area">
    <div class="container" id="id1">
        <div class="section-title">
            <h2>Our Amenities</h2>
           <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../corporate-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-university"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Virtual Offices</a></h3>
    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../corporate-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-work"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Full-Time Offices</a></h3>

                   <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../meeting-rooms">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-room"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Conference Rooms</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../virtual-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-location"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Global Access</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../dedicated-desks">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-security"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Discounted Products</a></h3>

                  <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../coworking-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-shop"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >B2B Opportunities</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Gallery Area -->
<section class="gallery-area pb-0" id="galleryarea">
    <div class="container">
        <div class="section-title">
            <h2>CorpWork Space Gallery</h2>
            <p>CORPWORK is a professional high-quality shared office with a mission to combine modern <a class="internallinks" href="/coworking-spaces">coworking spaces</a> and an emphasis on community. We&rsquo;re a meeting place for innovative minds brought together to re-think, re-create, and disrupt the waters in a variety of industries.</p>
        </div>

        <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".Coworking">Corporate Office</button>
            <button class="filter" data-filter=".virtual-office">Virtual Office</button>
            <button class="filter" data-filter=".dedicated-desk">Dedicated Desk</button>
            <button class="filter" data-filter=".private-office">Private Office</button>
            <button class="filter" data-filter=".meeting-spaces">Meeting Spaces</button>
        </div>
    </div>

    <div class="container-fluid">
        <div class="shorting">
            <div class="row">
                <div class="single-gallery-box mix Coworking">
                    <img src="assets/img/gallery-image/1.jpg" alt="image"  >

                    <a href="assets/img/gallery-image/1.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix virtual-office">
                    <img src="assets/img/gallery-image/2.jpg" alt="image">

                    <a href="assets/img/gallery-image/2.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix dedicated-desk">
                    <img src="assets/img/gallery-image/3.jpg" alt="image">

                    <a href="assets/img/gallery-image/3.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix private-office">
                    <img src="assets/img/gallery-image/4.jpg" alt="image">

                    <a href="assets/img/gallery-image/4.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix meeting-spaces">
                    <img src="assets/img/gallery-image/5.jpg" alt="image">

                    <a href="assets/img/gallery-image/5.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix Coworking">
                    <img src="assets/img/gallery-image/6.jpg" alt="image">

                    <a href="assets/img/gallery-image/6.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix virtual-office">
                    <img src="assets/img/gallery-image/7.jpg" alt="image">

                    <a href="assets/img/gallery-image/7.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix dedicated-desk">
                    <img src="assets/img/gallery-image/8.jpg" alt="image">

                    <a href="assets/img/gallery-image/8.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix private-office">
                    <img src="assets/img/gallery-image/9.jpg" alt="image">

                    <a href="assets/img/gallery-image/9.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>

                <div class="single-gallery-box mix meeting-spaces">
                    <img src="assets/img/gallery-image/10.jpg" alt="image">

                    <a href="assets/img/gallery-image/10.jpg" class="gallery-btn" data-imagelightbox="popup-btn"><i class="flaticon-expand"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->

<!-- Start Video Area -->
<section class="video-area ptb-100 pb-0">
    <div class="container">
        <div class="video-content">
            <h2>Watch this video presentation to know more about our Coworking</h2>
           <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="video-box">
            <img src="assets/img/video-img.webp" alt="image">

            <div class="play-video">
                <a class="video-btn" data-ilb2-video='{"controls":"controls", "autoplay":true, "sources":[{"src":"assets/img/videonew.mp4", "type":"video/mp4"}]}' data-imagelightbox="video"><i class="flaticon-play-button-1"></i></a>
                <span>Watch Now</span>
            </div>
        </div>
    </div>
</section>
<!-- End Video Area -->
