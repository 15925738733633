<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Private Office Spaces</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                     
                    <div class="img1">
                        <img src="assets/img/space/virtualoffice1.jpg" alt="image">
                    </div>
                   
                   

                    
                </div>
                
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Private Office Spaces</h1></b><br><br>
                    <h5>The new-age trendsetter is Private office.</h5>
                    <p><a class="internallinks" href="/private-office-spaces">Private office spaces</a> provide a lot of benefits to both the organization and the employee. 
                        It can be really beneficial in terms of cost, facilities, efficiency and productivity.</p>
                    <p>Corpwork private spaces are located in important locations of Hyderabad to fulfill all the private office space requirements  of business and corporations.</p>
                   <div class="btn-box">
                    <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                     Schedule A Visit
                    </button>                           
                    </div>
                </div>
               
            </div>
          
            
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    
                    <h5>Private spaces for all business</h5>
                    <p>Different kinds and styles of businesses rule the business and the corporate world. Corpwork provides private office spaces that welcome all kinds, sizes, and categories of business. A mid-sized business, freelancer, enterprise, or large team sizes businesses all can find their perfect pick for private spaces with Corpwork. Built and designed to serve all kinds of businesses, Corpwok provides all the necessary amenities like high-end technology, 24/7 super-fast wifi, private desk spaces, accessible <a class="internallinks" href="/meeting-rooms">meeting rooms,</a> and much more.</p>
                    <h5>Accelerate productivity</h5>
                    <p>Private office spaces no doubt give room for peace and more focused work. They are away from the common areas of other parts of the traditional office and provide the perfect zen to work more with a higher rate of productivity. Deliver more and crunch your productivity by shifting to private office spaces.</p>
                    <h5>Cost-saving</h5>
                    <p>Managing cost while setting up and the managing office is an important part of running the actual business. Doing smart and efficient cost-cutting while setting up the business adds value and money to the business. The private spaces of Corpwork come under an affordable range for all kinds and sizes of business. Easily set up and manage your office in one go by opting for Corpwork's private office spaces. Corpwork's private office space is fully furnished with the necessary office amenities and all set to full servicing!</p>
                    <h5>Professional Private and Personalised</h5>
                    <p>Private office spaces provide privacy and setting for any confidential tasks that you need to work on. If your business often has to work with confidential information then choosing private office spaces is the best choice for you. The <a class="internallinks" href="/dedicated-desks">dedicated desks</a> or private office spaces also give you the right space and focus to get your tasks done in time. Be professional yet add your personal elements to create the perfect workplace with Corpwork's private office spaces!</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title"><a class="internallinks" href="/coworking-spaces-in-kphb">Our Place</a></span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
