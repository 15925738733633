import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-schuduletourrequests',
  templateUrl: './schuduletourrequests.component.html',
  styleUrls: ['./schuduletourrequests.component.css']
})
export class SchuduletourrequestsComponent implements OnInit {
  schudules : any;
  checkschudule = false;
  constructor(private clients : ClientsService) { }

  async ngOnInit(){
    console.log("welcome to booking");
    await this.clients.getScheduleRequests().pipe(map(res => {
       console.log(res);
       if(res != '') {
         this.checkschudule = true;
       }
       this.schudules = res;
     })).toPromise();
 
   }
  

}
