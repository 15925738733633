<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>The Place</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Place</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Floor Plans Area -->
<section class="floor-plans-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <!-- <div class="section-title">
            <h2>Our Spaces</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div> -->

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li id="ngcontent_head"><a>
                            <h1 style="font-size: 26px;color: #e3d5bf;">Coworking Spaces In KPHB</h1>
                        </a></li>
                        
                         
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12 content" style="padding-right:50px">
                                    <div class="tabs_item_content">
                                     
                                        <p> </p>

                                        <ul class="features-list">
                                            <li>Floor No <span>2</span></li>
                                            <li>Rooms <span>8</span></li>
                                            <li>Total Area <span>2800 sqft</span></li>
                                            <li>Bathroom <span>Yes</span></li>
                                            <li>Windows <span>10</span></li>
                                        </ul>

                                        <!-- <a href="/" class="default-btn">Schedule a Visit <span></span></a> -->
                                        <div class="btn-box">
                                            <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                                             Schedule A Visit
                                            </button>                           
                                            </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 image">
                                    <div class="about-image">
                     
                                        <div class="img1">
                                            <img src="assets/img/floor-img1.png" alt="image">
                                        </div> </div>
                                     
                                </div>
                            </div>
                        </div>

                       
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Floor Plans Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <h5>Affordability with Functionality
                    </h5>
                    <p>Renting an office space is not an easy task and involves a lot of extra costs. Corpwork coworking spaces are equipped with all the office amenities that can be used by every employee or member. If affordability and functionality are what you are looking for then Corpwork coworking space is your answer. With Corpwork coworking spaces get <a class="internallinks" href="/dedicated-desks">dedicated desks</a> for rent or lease as per your requirement. All the Corpwork coworking spaces are pre-filled with office amenities that are ready to use!</p>
                    <h5>Streamlined office spaces</h5>
                    <p>Bring a structure and pace to your office spaces after moving into the <a class="internallinks" href="/coworking-spaces">co-working office spaces</a> of Corpwork. The daily operational office tasks can become mundane and often attract chaos. The fully ready to serve office spaces by Corpwork makes sure that everything is streamlined and in order. Corpwork comes with dedicated desks for lease or rent, a meeting room, <a class="internallinks" href="/virtual-office-spaces">virtual spaces</a>, and fully equipped with all the office amenities. Now, you enjoy an ordered workday while Corpwork does all the back work!</p>
                    <h5>Coworking spaces for everyone</h5>
                    <p>Rent an office space of your choice as per your business style and requirement. Corpwork provides co-working spaces for all kinds of businesses and enterprises. Go by the choice of renting or leasing dedicated desks whichever suits your budget and finances. Get access to the <a class="internallinks" href="/meeting-rooms">meeting rooms</a> by renting the meeting rooms at your convenience. Corpwork coworking spaces are designed for all kinds of corporates and easily fit your mode of working. With the age of digitalization and the current scenarios, get virtual office space of your choice to continue on your ongoing office work.</p>
                    <h5>Work in style with Corpwork!</h5>
                    <p>Encourage your working spirit with stylish and functional office spaces. Get a dedicated desk for yourself and work at your own movement. Rent and work in places where style meets functionality. Corpwork rents the working spaces that are fully furnished for your office to start running in minutes. Now, don't be under the mindset that a designer coworking space is out of your reach. With Corpwork get the designer, fully functional coworking. meeting or virtual office spaces as per your choice.</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->