<div class="page-title-area item-bg2">
<div class="d-table">
    <div class="d-table-cell">
        <div class="container">
            <div class="page-title-content">
                <h1 style="color: aliceblue;">Privacy Policy</h1>
                <!-- <h3 style="color:white">Coming Soonnn</h3> -->
                 
            </div>
        </div>
    </div>
</div>
</div>
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <p>We do everything to secure and maintain your personal information with us.</p>
                    <p>This privacy policy gives you the information about our practices, and choices we make about the way we collect and use your information provided.</p>
                    <h3>Who does this Privacy Policy apply to?</h3>
                    <p>We collect personal information from a variety of sources. We do not collect sensitive information:</p>
                    <ul>
                        <li>Employees</li>
                        <li>Customers</li>
                        <li>Clients</li>
                        <li>Business partners</li>
                        <li>Suppliers</li>
                        <li>Contractors</li>
                        <li>Shareholders</li>
                    </ul>
                    <h3>Requesting for Personal Information:</h3>
                    <p>In our website, asks you to provide personal information that will help us to enhance your site visit or to follow up with you after your visit. </p>
                    <p>In each of the instances above, we may ask for your name, e-mail address, phone number, address and type of business, as well as other similar information that is needed to register to our services or offers. If we ever ask for significantly different information we will inform you. In the case of newsletters or mailing lists, you will be able to “unsubscribe” to these mailings at any time.</p>
                    <h3>Use of Personal Information:</h3>
                    <p>The personal information you provide will be kept confidential, and used to support your customer relationship with us. </p>
                    <p>Stakeholders or management who have access to your information are required to keep the personal information confidential and not use it for any other purpose. </p>
                    <h3>Disclosure of Information:</h3>
                    <p>We does not sell, rent, loan, trade, or lease any personal information collected at our site. This includes any licensing forms or e-mail lists.</p>
                    <h3>Security of Personal Information:</h3>
                    <p>We will take all reasonable steps to ensure that your personal information is stored in a secure environment protected from unauthorised access, modification and disclosure. We use industry standard safeguards to ensure the security of personal information through physical, electronic and managerial procedures. </p>
                    <p>When we no longer requires personal information, it will safely destroy the information and delete it from our systems.</p>
                    <p>Third party Internet sites and services accessible through this site have separate privacy and data collection practices independent of us. We are not responsible for these independent policies or actions. You are solely responsible for maintaining the secrecy of your passwords or any account information. Please be careful and responsible whenever you are online. Be aware that if you post personal information online that is accessible to the public, you may receive unsolicited messages from other parties in return.</p>
                    <h3>Access to Personal Information:</h3>
                    <p>You or a third party nominated by you in writing may request access to any of the personal information that we hold about you. Upon your request, we will provide access to the personal information that it holds about you.</p>
                    <p>Your request for access will be dealt with in a reasonable time. We may provide access to personal information without your consent only in certain circumstances, including but not limited to: emergency situations:</p>
                    <ul>
                        <li>To comply with legislative requirements</li>
                        <li>Law enforcement</li>
                        <li>Preventing a serious threat to an individual’s life, health or safety or a serious threat to public health or public safety</li>
                    </ul>
                    <h3>Contact Us:</h3>
                    <p>If you wish to gain access to or correct your personal information or make a complaint about a breach of your privacy, you should contact:</p>
                    <p>Address:CorpWork Hub
                        81 Jubilee Enclave , HITEC City
                        Hyderabad, Telangana 500081</p>
                    <p>Email: space@thecorpwork.com</p>
                    <p>Website: https://thecorpwork.com/</p>
                    <p>We will respond to your query or complaint as soon as possible. We reserves the right to change this Privacy Policy at any time.</p>
                </div>
            </div>
        </div>
    </div>
</section>
 
