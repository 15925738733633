import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      this.titleService.setTitle("Services | Private Offie & Coworking| Startup Incubation Center in Hyderabad");
      this.metaTagService.updateTag({name:'description',content:'We created a collaborative office environment space for innovative minds and for an aspiring startups who re-think, re-create. the CORPWORK offers modern corporate Coworking,Virtual Office, Private Office shared office spaces & Startup incubation center located at prime locations of Hyderabad'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'CoWorking Space in Hyderabad. Shared Office Space for Startups in Hyderabad' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

  ngOnInit(): void {
  
  }

}
