import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { 
      this.titleService.setTitle("FAQs - theCORPWORK | CoWorking Space");
      this.metaTagService.updateTag({name:'description',content:'Have questions about Services? You are on the right Page,Get answers to general queries regarding your office space'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'FAQ- Coworking space Questions and Answers | theCORPWORK' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

  ngOnInit(): void {
    
  }

}
