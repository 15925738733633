import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-corporate-office-spaces-in-gachibowli',
  templateUrl: './corporate-office-spaces-in-gachibowli.component.html',
  styleUrls: ['./corporate-office-spaces-in-gachibowli.component.css','../../../assets/css/mystyles.css','../../../assets/css/responsive.css'],
  
})
export class CorporateOfficeSpacesInGachibowliComponent implements OnInit {
  
  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { 
      this.titleService.setTitle("Best Corporate Office Spaces in Gachibowli, Hyderabad | Coworking Space");
      this.metaTagService.updateTag({name:'description',content:'Book an office space for rent in Gachibowli, Business Centre, plug & play office spaces, Virtual Office & Meeting Rooms in Gachibowli Hyderabad. theCORPWORK'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Corporate Office spaces in hitech city, hyderabad' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: '' },
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

    ngOnInit() {
      
        
    }

}
