import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
 
  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      
      this.titleService.setTitle("Corporate Office Spaces| Coworking | Business Centre | Shared Office");
      this.metaTagService.updateTag({name:'description',content:'theCORPWORK is Coworking space located in Hyderabad and serving - 24/7 air-conditioned, fully serviced end to end office spaces.Choose from dedicated Private offices, Virtual offices & Startup incubation center.'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Coworking Space in Hitec City Hyderabad, theCORPWORK offers fully serviced office spaces, Startup incubation center, Rent Office, business center, meeting rooms.' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: '' },
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
     }

    ngOnInit() {
     
        
    }

}
