import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-coworking-spaces-in-kphb',
  templateUrl: './coworking-spaces-in-kphb.component.html',
  styleUrls: ['./coworking-spaces-in-kphb.component.css','../../../assets/css/mystyles.css','../../../assets/css/responsive.css']
})
export class CoworkingSpacesInKphbComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { 
      this.titleService.setTitle("Coworking Space for Rent in Kukatpally, Hyderabad| Shared Office Spaces");
      this.metaTagService.updateTag({name:'description',content:'Fully Furnished Office Space for Rent KPHB, Hyderabad.professional coworking,Shared Office Space KPHB. flexible modern businees space. coworking Office space for rent in kukatpally'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Coworking space in kphb, Office space for rent in Hyderabad' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

    ngOnInit() {
     
        
    }
}
