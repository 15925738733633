<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>The Place</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Place</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Floor Plans Area -->
<section class="floor-plans-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <!-- <div class="section-title">
            <h2>Our Spaces</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div> -->

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                         
                        <li id="ngcontent_head"><a>
                            <h1 style="font-size: 26px;color: #e3d5bf;">Corporate Office Spaces In Hitech City</h1>
                        </a></li>
 
                    </ul>

                    <div class="tab_content">
                       
                        

                        <div class="tabs_item">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12 image">
                                    
                                    <div class="about-image">
                     
                                        <div class="img1">
                                            <img src="assets/img/space/virtualoffice1.jpg" alt="image">
                                        </div>
                                       
                                       
                    
                                        
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 content">
                                    <div class="tabs_item_content">
                                        
                                        <p>HITEC City is a busy tech industry hub. It's filled with office parks and served by Indian, Italian, and BBQ restaurants, as well as lounge bars and fast-food outlets, clustered mainly around Hitech City Main Road.</p>

                                        <ul class="features-list">
                                            <li>Floor No <span>6</span></li>
                                            <li>Rooms <span>25</span></li>
                                            <li>Total Area <span>20,000 sqft</span></li>
                                            <li>Bathroom <span>Yes</span></li>
                                            <li>Windows <span>50</span></li>
                                        </ul>

                                        <div class="btn-box">
                                            <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                                             Schedule A Visit
                                            </button>                           
                                            </div>                                    </div>
                                </div>
                            </div>
                        </div>

                         

                        

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Floor Plans Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <p>Hitech city is the corporate hub of Hyderabad. It is expansive in its corporate nature extending  from small to medium scale businesses to big known brands to startup aspirants. It is a place 
                        for all kinds of corporations and businesses making it more sense to set up an office space here.</p>
                    <p>Hitech city is the dreamland for corporate professionals and finding a fitting place for your business or enterprise offices can be no less than running a marathon. But with Corpwork, office spaces for rent you can find your right place in just steps!</p>
                    <h5>Countless benefits at your desk</h5>
                    <p>
                        The <a class="internallinks" href="/corporate-office-spaces">fully serviced office spaces</a> of Corpwork make sure that your office runs at full capacity with all the essential amenities. Get access to 24-hour fast wifi, private desks, and cabins, on-the toes support team from Corpwork. Utilize the fully furnished meeting and <a class="internallinks" href="/meeting-rooms">conference rooms</a> for your daily team meetings and hurdles and have thriving work days!
                    </p>
                    <h5>Simplified office spaces</h5>
                    <p>Create professional and inspiring work environments that suit your business needs and objectives. Corpwork provides corporate office spaces for all kinds of business styles, sizes, 
                        and teams. Get fully serviced office spaces, ready to be occupied and with all the office amenities.</p>
                    <h5>Work where everyone works!</h5>
                    <p>Find private or <a class="internallinks" href="/corporate-office-spaces">corporate office space</a> in the desiring corporate location of Hyderabad, Hitech city with Corpwork. Setup your high scale offices where your business runs and decisions are made in the limelight of the city. Be where there is the hustle of offices for constant inspiration from your environment!</p>
                    <h5>Effortless office spaces</h5>
                    <p>Easily create a visionary workplace that promotes innovation and improves business performance by taking Office space for rent through Corpwork. Forget the struggle of finding 
                        the right <a class="internallinks" href="/private-office-spaces">private office</a> as Corpwork efficiently does this for you. Quickly move into fully serviced, professional work paces with the ready-made amenities with Corpwork.</p>
                    <p>Find your ideal and fit office space for rent according to your business and corporate needs. Get access to the full amenities provided by the Corpwork like 24-hour fast broadband/wifi internet, <a class="internallinks" href="/dedicated-desks">dedicated office spaces</a>, meeting, conference, and newsroom as per your demands. Corpwork takes the full responsibility of making your office and workspace experience as seamless as possible while you and your team focus on your goals!</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" id="high_desk" >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper" id="hightech_img">
                                            <img id="placeimagebg" src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper" id="hightech_img">
                                            <img id="placeimagebg" src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

   

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
