<!-- Start Page Title Area -->
<div class="page-title-area item-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1 style="color:aliceblue">Our Packages</h1>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Package Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->
<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <!--<span class="sub-title">Our Plans</span>
            <h2>Our Office Spaces</h2>-->
          <!--  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header bg1">
                        <h3>Corporate Office</h3>
                    </div>
         <!--
                    <div class="price">
                        <sub>INR</sub>4500<sub>/mo</sub>
                    </div>
      -->
                    <div class="book-now-btn">
                        <a  class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 24/7 Access</li>
                        <li><i class="flaticon-check-mark"></i> Cleaning Service</li>
                        <li><i class="flaticon-check-mark"></i> High Speed Wifi/ Internet</li>
                        <li><i class="flaticon-check-mark"></i> Opening Hours (8:00 – 22:00)</li>
                        <li><i class="flaticon-check-mark"></i> Utilities Included</li>
                        <li><i class="flaticon-check-mark"></i> Access to Kitchen Lounge</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header bg2">
                        <h3>Virtual Office</h3>
                    </div>
<!--
                    <div class="price">
                        <sub>INR</sub>2000<sub>/mo</sub>
                    </div>
-->
                    <div class="book-now-btn">
                        <a  class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 24/7 Access</li>
                        <li><i class="flaticon-check-mark"></i> Cleaning Service</li>
                        <li><i class="flaticon-check-mark"></i> High Speed Wifi/ Internet</li>
                        <li><i class="flaticon-check-mark"></i> Opening Hours (8:00 – 22:00)</li>
                        <li><i class="flaticon-check-mark"></i> Utilities Included</li>
                        <li><i class="flaticon-check-mark"></i> Access to Kitchen Lounge</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header bg3">
                        <h3>Private Cabins</h3>
                    </div>
<!--
                    <div class="price">
                        <sub>INR</sub>10,000<sub>/mo onwards</sub>
                    </div>
-->
                    <div class="book-now-btn">
                        <a  class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 24/7 Access</li>
                        <li><i class="flaticon-check-mark"></i>  Cleaning Service</li>
                        <li><i class="flaticon-check-mark"></i> High Speed Wifi/ Internet</li>
                        <li><i class="flaticon-check-mark"></i> Opening Hours (8:00 – 22:00)</li>
                        <li><i class="flaticon-check-mark"></i>  Utilities Included</li>
                        <li><i class="flaticon-check-mark"></i> Access to Kitchen Lounge</li>
                    </ul>
                </div>
            </div>
            
             <div class="col-lg-3 col-md-6 col-sm-6 ">
                <div class="single-pricing-box">
                    <div class="pricing-header bg1">
                        <h3>Coworking</h3>
                    </div>
<!--
                    <div class="price">
                        <sub>INR</sub>4500<sub>/mo</sub>
                    </div>
-->
                    <div class="book-now-btn">
                        <a  class="default-btn">Book Now <span></span></a>
                    </div>

                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 24/7 Access</li>
                        <li><i class="flaticon-check-mark"></i> Cleaning Service</li>
                        <li><i class="flaticon-check-mark"></i> High Speed Wifi/ Internet</li>
                        <li><i class="flaticon-check-mark"></i> Opening Hours (8:00 – 22:00)</li>
                        <li><i class="flaticon-check-mark"></i> Utilities Included</li>
                        <li><i class="flaticon-check-mark"></i> Access to Kitchen Lounge</li>
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Event Area -->
<!--
<section class="event-area ptb-100">
    <div class="container">
        <div class="single-event-box">
            <div class="event-image">
                <div class="image bg1">
                    <img src="assets/img/blog-image/1.jpg" alt="image">
                </div>
            </div>

            <div class="event-content">
                <div class="content">
                    <h3><a >Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercit consequat.</p>

                    <div class="date">
                        18
                        <sup>Jan</sup>
                        <sub>2020</sub>
                    </div>

                    <ul>
                        <li>
                            <span>Where</span>
                            Fort Mason Center Victoria City, Canada
                        </li>

                        <li>
                            <span>When</span>
                            Sunday (3.30PM - 6.30PM)
                        </li>
                    </ul>

                    <a  class="default-btn">More Info <span></span></a>
                </div>
            </div>
        </div>

        <div class="single-event-box">
            <div class="event-content">
                <div class="content">
                    <h3><a >Ruby on Rails Framework</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercit consequat.</p>

                    <div class="date">
                        20
                        <sup>Jan</sup>
                        <sub>2020</sub>
                    </div>

                    <ul>
                        <li>
                            <span>Where</span>
                            Fort Mason Center Victoria City, Canada
                        </li>

                        <li>
                            <span>When</span>
                            Sunday (3.30PM - 6.30PM)
                        </li>
                    </ul>

                    <a  class="default-btn">More Info <span></span></a>
                </div>
            </div>

            <div class="event-image">
                <div class="image bg2">
                    <img src="assets/img/blog-image/2.jpg" alt="image">
                </div>
            </div>
        </div>

        <div class="single-event-box">
            <div class="event-image">
                <div class="image bg3">
                    <img src="assets/img/blog-image/3.jpg" alt="image">
                </div>
            </div>

            <div class="event-content">
                <div class="content">
                    <h3><a >Angular Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercit consequat.</p>

                    <div class="date">
                        22
                        <sup>Jan</sup>
                        <sub>2020</sub>
                    </div>

                    <ul>
                        <li>
                            <span>Where</span>
                            Fort Mason Center Victoria City, Canada
                        </li>

                        <li>
                            <span>When</span>
                            Sunday (3.30PM - 6.30PM)
                        </li>
                    </ul>

                    <a  class="default-btn">More Info <span></span></a>
                </div>
            </div>
        </div>

        <div class="single-event-box">
            <div class="event-content">
                <div class="content">
                    <h3><a >WordPress Framework</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercit consequat.</p>

                    <div class="date">
                        26
                        <sup>Jan</sup>
                        <sub>2020</sub>
                    </div>

                    <ul>
                        <li>
                            <span>Where</span>
                            Fort Mason Center Victoria City, Canada
                        </li>

                        <li>
                            <span>When</span>
                            Sunday (3.30PM - 6.30PM)
                        </li>
                    </ul>

                    <a  class="default-btn">More Info <span></span></a>
                </div>
            </div>
            
            <div class="event-image">
                <div class="image bg4">
                    <img src="assets/img/blog-image/4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
-->
<!-- End Event Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="partner-title">
            <h2>Trusted by Companies</h2>
        </div>
        <ngb-carousel #carousel [interval]="1000">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>
<!--
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a >
                    <img src="/assets/img/partner-image/1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/5.png" alt="image">
                </a>
            </div>
 
            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a >
                    <img src="assets/img/partner-image/7.png" alt="image">
                </a>
            </div>
           
        </div>
         -->
    </div>
</section>
<!-- End Partner Area -->
