 <!-- Start Page Title Area -->
 <div class="page-title-area item-bg5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h1 class="sub-title">Contact Us</h1>
                    <h2>Say Hello</h2>
                    <p>Your email address will not be published. We promise not to spam!</p>
                </div>

                <div class="contact-info">
                    <ul class="contact-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Address</span>
                            CorpWork Hub<br>81 Jubilee Enclave , HITEC City <br>Hyderabad, Telangana 500081
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <span>Email</span>
                            <a >space@thecorpwork.com</a>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Phone</span>
                            <a >+91 70328 91053 </a>
                           
                        </li>
                    </ul>

                    <ul class="social">
                        <li><a href="https://www.facebook.com/thecorpwork"  target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a  href="https://twitter.com/thecorpwork" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/corpwork/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/thecorpwork/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Have a Question?</span>
                    <h2>Send a Message</h2>
                    <p>Your email address will not be published. We promise not to spam!</p>
                </div>

                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactUs" (ngSubmit)="submitContact()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" formControlName="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder="Your Message"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div *ngIf="iserrorContact" class="alert alert-danger">{{errorMessageContact}}</div>
                                <div *ngIf="isContactSubmitted" class="alert alert-success">{{submitMessage}}</div>

                                <div class="form-group">
                                    <input type="submit" value="Submit" class="btn float-right login_btn">
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>
<!-- End Contact Area -->
