import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      this.titleService.setTitle("News | Office Space for RENT");
      this.metaTagService.updateTag({name:'description',content:'theCORPWORK office spaces in Hyderabad - Rent or Lease Office Spaces suitable to your Business. Best choice with the quality features. Corporate Office / Virtual Office / Dedicated Desk / Private Office / Meeting Spaces'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Office space for rent in Hitec City Hyderabad, shared co working space, business centre' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

  ngOnInit(): void {
   
  }

}
