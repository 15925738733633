import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var $:any;
@Component({
  selector: 'app-private-office-spaces',
  templateUrl: './private-office-spaces.component.html',
  styleUrls: ['./private-office-spaces.component.css','../../../assets/css/mystyles.css','../../../assets/css/responsive.css']
})
export class PrivateOfficeSpacesComponent implements OnInit {
  kphb:string='kphb';
  gachibowli:string='gachibowli';
  hitechcity:string="hitechcity";
  kondapur:string='kondapur';
  corptab:any='kphb';
  
  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
   
      this.titleService.setTitle("Plug and Play Office Space | Plug and Play Office Space For Rent in Hyderabad");
      this.metaTagService.updateTag({name:'description',content:'Rent/Lease a Private Office Space in Hyderabad, Book and set up your businees at the perfect shared office space at prime locations of at KPHB, HITEC CITY, GACHIBOWLI, KONDAPUR, Hitec City.- theCORPWORK'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Private Office Space for Rent in Hitech City - Hyderabad' });
            
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);
   
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
  
   //this.loadScript('assets/js/jquery.min.js');
   //this.loadScript('assets/js/jquery.meanmenu.js');
   //this.loadScript('assets/js/jquery.ajaxchimp.min.js');
   //this.loadScript('assets/js/owl.carousel.min.js');
   //this.loadScript('assets/js/bootstrap.min.js');
   
   //this.loadScript('assets/js/form-validator.min.js');
   //this.loadScript('assets/js/imagelightbox.min.js');
   
   //this.loadScript('assets/js/mixitup.min.js');
   //this.loadScript('assets/js/parallax.min.js');
   //this.loadScript('assets/js/popper.min.js');
   //this.loadScript('assets/js/wow.min.js');
    
   //this.loadScript('assets/js/main.js');
      var _this=this;
     $(document).ready(function() {
       function onHashChange() {
           var hash = window.location.hash;
     console.log(hash)
     console.log(hash.indexOf('gachibowli'));
           if (hash) {
            if( hash.indexOf('kphb')==1)
              _this.showDiv('kphb')
              else if( hash.indexOf('hitechcity')==1)
              _this.showDiv('hitechcity')
              else if( hash.indexOf('gachibowli')==1){
              _this.showDiv('gachibowli')
              console.log("inside");
              }
              else if( hash.indexOf('kondapur')==1)
              _this.showDiv('kondapur')
               // using ES6 template string syntax
              // $(`[data-toggle="corptab"][href="${hash}"]`).trigger('click');
           }
       }
   
       //window.addEventListener('hashchange', onHashChange, false);
       onHashChange();
   });
   }
 
   ngOnInit(): void {
     
  
   }
   showDiv(corptab){
     this.corptab=corptab;
     console.log(this.corptab)
   }
   public loadScript(url: string) {
     console.log(url);
     const body = <HTMLDivElement> document.body;
     const script = document.createElement('script');
     script.innerHTML = '';
     script.src = url;
     script.async = false;
     script.defer = true;
     body.appendChild(script);
   }
 }
  
  
 