
        <!-- Start Page Title Area -->
        <div class="page-title-area item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h1 class="text-white">About Us</h1>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-title">
                            <h2>About Us TESTING</h2>
                            <h2>We offer creative working environments that suit your business</h2>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-text">
                            <p>Work better together! <br>

The rise of digital technology and the shared economy has changed the way we work forever. The demand for efficiency, agility and innovation is greater that it has ever been before.
The proliferation of <a class="internallinks" href="/coworking-spaces">coworking spaces</a> globally shows that the way we work is changing. <br><br>

At CorpWork our model integrates and connects members into one community, while at the same time, providing them the option of working independently. We continually disrupt the status quo and redefine collaborative working. <br><br>

In our communities, we connect dynamic entrepreneurs, start-ups and large corporations. People are at the heart of what we do, as we create networking events and strategic partnerships for our members and partners to gain insights, collaborate, and help each other. Our passion for customer satisfaction ensures our members find the same hospitality – comfort and peace of mind – no matter where they are. We make work better by revolutionising how people work, empowering our ever-growing community with exceptional flexibility, opportunities and experiences.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Area -->
 <!-- Start Services Area -->
 <section class="services-area">
    <div class="container" id="id1">
        <div class="section-title">
            <h2>Our Amenities</h2>
           <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../corporate-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-university"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Virtual Offices</a></h3>
    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../corporate-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-work"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Full-Time Offices</a></h3>

                   <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../meeting-rooms">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-room"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Conference Rooms</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../virtual-office-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-location"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Global Access</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-4">
                <a routerLink="../dedicated-desks">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-security"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >Discounted Products</a></h3>

                  <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>

           <div class="col-lg-2 col-md-3 col-sm-4">
            <a routerLink="../coworking-spaces">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-shop"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a >B2B Opportunities</a></h3>

                    <!--
                    <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt ut incididunt labore et dolore.</p>

                    <a  class="read-more-btn">Learn More</a> -->

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</section>
       
<!-- End Services Area -->


       
        <!-- Start Our Mission Area -->
        <section class="our-mission-area"  [attr.id]='ourmission'>
            <div class="container-fluid p-0">
                <div class="row m-0">
                    <div class="col-lg-2 col-md-6 p-0">
                        <div class="mission-image bg-1">
                            <img src="assets/img/mission-img1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 p-0">
                        <div class="mission-text">
                            <div class="icon">
                                <i class="flaticon-target"></i>
                            </div>
                             <h3>Our Mission</h3>
                            
                            <p>Your personal Corporate Space!<br>

We are committed to providing a conducive, comfortable, and inspiring workspace environment to encourage you to create, produce, & get your work done.</p>

                            <!-- <a  class="default-btn">Learn More <span></span></a> -->
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 p-0">
                        <div class="mission-image bg-2">
                            <img src="assets/img/mission-img2.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 p-0">
                        <div class="mission-text">
                            <div class="icon">
                                <i class="flaticon-award"></i>
                            </div>

                            <h3>Our History</h3>
                            <p>

CorpWork started in 2019 by Raghuveer a young entrepreneur from Singapore with Tech & Development background, Worked globally in most reputed MNCs and understood first hand what it takes to be a great workplace. At CorpWork you can experience the enjoyment of working solo in a true corporate environment.</p>

                            <!-- <a  class="default-btn">Learn More <span></span></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Our Mission Area -->

        <!-- Start Team Area -->
		<!--
        <section class="team-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Our Team</span>
                    <h2>Meet Our Experts</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="team-slides owl-carousel owl-theme">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-image/2.jpg" alt="image">

                            <ul class="social">
                                <li><a ><i class="fab fa-facebook-f"></i></a></li>
                                <li><a ><i class="fab fa-twitter"></i></a></li>
                                <li><a ><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a ><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Lee Munroe</h3>
                            <span>Lead Designer</span>
                        </div>
                    </div>

                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-image/3.jpg" alt="image">

                            <ul class="social">
                                <li><a ><i class="fab fa-facebook-f"></i></a></li>
                                <li><a ><i class="fab fa-twitter"></i></a></li>
                                <li><a ><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a ><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Calvin Klein</h3>
                            <span>Lead Developer</span>
                        </div>
                    </div>

                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-image/4.jpg" alt="image">

                            <ul class="social">
                                <li><a ><i class="fab fa-facebook-f"></i></a></li>
                                <li><a ><i class="fab fa-twitter"></i></a></li>
                                <li><a ><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a ><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>Lead Architecure</span>
                        </div>
                    </div>

                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-image/1.jpg" alt="image">

                            <ul class="social">
                                <li><a ><i class="fab fa-facebook-f"></i></a></li>
                                <li><a ><i class="fab fa-twitter"></i></a></li>
                                <li><a ><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a ><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Alastair Cook</h3>
                            <span>Marketing Manager</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		-->
        <!-- End Team Area -->
         <br>
        <!-- Start Join Area -->
        <section class="join-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="join-content">
                    <h2>CorpWork is a community where everyone is welcome.</h2>
                   <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>-->

                    <a href="/" class="default-btn"><a class="join1" href="/contact">Join Now</a> <i class="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>
        </section>
        <!-- End Join Area -->

        <!-- Start Feedback Area -->
		<!--
        <div class="feedback-area ptb-100">
            <div class="container">
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="client">
                            <img src="assets/img/partner-image/1.png" alt="image">
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <p>Quis ipsum suspendisse ultrices gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <div class="client">
                            <img src="assets/img/partner-image/2.png" alt="image">
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <p>Sed do eiusmod tempor incididunt ut labore et dolore magna, lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis ipsum suspendisse ultrices gravida.</p>

                        <div class="client">
                            <img src="assets/img/partner-image/3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
		-->
        <!-- End Feedback Area -->


             
        
                 <!-- Start Partner Area -->
         <section class="partner-area ptb-100 bg-f8f8f8" >
            <div class="container text-center items-align-center" id="id2" style=" width: 100% !important; position: relative !important; overflow: hidden !important;">
                <div class="partner-title" >
                    <h2 [attr.id]='collaborations'>Collaborations </h2>
                    
                </div>
                <ngb-carousel #carousel [interval]="2000">
                    <ng-template ngbSlide>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/1.png" alt="image">
                        </a>
                    </div>
</ng-template>
<ng-template ngbSlide>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/2.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/3.png" alt="image">
                        </a>
                    </div>
</ng-template>
<ng-template ngbSlide>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/3.png" alt="image">
                        </a>
                    </div>
</ng-template>
<ng-template ngbSlide>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/4.png" alt="image">
                        </a>
                    </div>
</ng-template>
<ng-template ngbSlide>
                    <div class="single-partner-item">
                        <a >
                            <img src="assets/img/partner-image/5.png" alt="image" style="margin-top: -20px;">
                        </a>
                    </div>
                    </ng-template>
               
                </ngb-carousel>
            </div>
        </section> 
        <!-- End Partner Area -->
  

        
        
       