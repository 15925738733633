import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-coworking-spaces-in-kondapur',
  templateUrl: './coworking-spaces-in-kondapur.component.html',
  styleUrls: ['./coworking-spaces-in-kondapur.component.css','../../../assets/css/mystyles.css']
})
export class CoworkingSpacesInKondapurComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { 
      this.titleService.setTitle("Coworking Space in Kodapur, Hyderabad | Coworking Space");
      this.metaTagService.updateTag({name:'description',content:'Best Coworking Space in Kondapur, We are located at KPHB, HITEC CITY, GACHIBOWLI, KONAPUR.Book fully modern furnished office space at your primary locations,  Shared Office Spaces for Rent in Kondapur.'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'Coworking Space in Kondapur, Hyderabad' });
            
    this.metaTagService.addTags([
      // {name:'description',content:''},
      // { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      
      { charset: 'UTF-8' }
    ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);}

    ngOnInit() {
     
        
    }

}
