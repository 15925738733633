<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Dedicated Desks</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="img1">
                        <img src="assets/img/space/dedicateddesk1.jpg" alt="image">
                    </div>
 

                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Dedicated Desks</h1></b><br><br>
                    <h5>A balance between your privacy and creativity.</h5>
                    <p>With the concept of coworking spaces, dedicated desks are the added services that make your experience amazing being a member in the space. When coworking gives you an option to isolate yourself from the crowd and work on your ideas, dedicated desks make these whole processes more customized and centralized. </p>
                    <p></p>
                    <div class="btn-box">
                        <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                         Schedule A Visit
                        </button>                           
                        </div>
                </div>
            </div>
         
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <h5>A balance between your privacy and creativity.</h5>
                    <p>With the concept of <a class="internallinks" href="/coworking-spaces">coworking spaces,</a> dedicated desks are the added services that make your experience amazing being a member in the space. When coworking gives you an option to isolate yourself from the crowd and work on your ideas, dedicated desks make these whole processes more customized and centralized.</p>
                    <h5>Pocket-friendly office spaces</h5>
                    <p>Moving to dedicated <a class="internallinks" href="/corporate-office-spaces">office spaces</a> is one of the easiest and the most cost-efficient options in the office spaces scenario. Renting a desk not just comes with a single desk but with all its amenities. Take dedicated desks for lease or rent in prominent parts of Hyderabad like Hitech city and access to all the office amenities and a much-upgraded format with Corpwork. Be assured of ready-service dedicated desks and world-class support from Corpwork in setting up and maintaining your private dedicated desks.</p>
                    <h5>Dedicated Desk Benefits</h5>
                    <p>Dedicated desks bring their own advantages, especially within <a class="internallinks" href="/coworking-spaces-in-kphb">coworking office spaces.</a> There are many among the corporate employees who would long for a private and dedicated space to work. By renting dedicated spaces with Corpwork work as per your working style. Be it a freelancer or a team member, Corpwork makes sure you're happy with your work environment. All in all, Corpwork gives the possibility of finding your space and also working alongside your peers with a dedicated desk system.</p>
                    <h5>Grow your business with dedicated desks</h5>
                    <p>Offer your business a chance to flourish and grow simply by implementing the dedicated desk work culture. With Corpwork easily shifts into a dedicated desk culture by just renting or leasing out dedicated desks at notable locations of Hyderabad like Hitech City and Madhapur. The dedicated desks provided by Corpwork are fully operational and ready to be occupied.</p>
                    <h5>Get a blend of everything</h5>
                    <p>Dedicated desk gives a lot of underlying advantages both to a business owner and the employee. From cost efficiency to accessibility of all the office amenities in one spot, dedicated desks sure serve as a great choice for working. Opting for dedicated desks by renting or leasing gives the chance to add personalization and feel comfortable in the space. Give your employees the privacy they long for in a professional workplace while using meetings,<a class="internallinks" href="/meeting-rooms"> conference rooms</a> for all the collaborations.</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12" id="carousel1">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;">
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2" style="width: 100% !important; position: relative !important; overflow: hidden !important;">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper pt-4">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper pt-4">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper pt-4">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper pt-4">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper pt-4">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2" style="margin-top: -20px;">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
