<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Virtual Office Spaces</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                     
                    <div class="img1">
                        <img src="assets/img/space/virtualoffice1.jpg" alt="image">
                    </div>
                   
                   

                    
                </div>
                
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Virtual Office Spaces</h1></b><br><br>
                    <h5>The new-age trendsetter is virtual office.</h5>
                    <p>Looking at the current work from home scenarios, we are quite habituated with the word virtual. So, the virtual office spaces are assisting employees and entrepreneurs to manage all of their physical works via the internet, when they all are working remotely. There are loads of benefits of having virtual office spaces for your business, where in your absence most of your business deeds are handled carefully with no physical intervention.</p>
                   <p></p>
                   <div class="btn-box">
                    <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                     Schedule A Visit
                    </button>                           
                    </div>
                </div>
               
            </div>
          
            
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <p>With the current ongoing scenarios and the changing trends, businesses and corporates are moving to flexible work hours. And this system of working is streamlined with virtual <a class="internallinks" href="/corporate-office-spaces-in-gachibowli">office spaces.</a></p>
                    <p>Virtual office spaces are for the modern needs of the business that can easily tackle the office work-related challenges. Corpwork virtual office spaces are made with the same solution, to provide a fully comprehensive and simple virtual office space that matches your flexibility and your business style!</p>
                    <h5>The new-age trendsetter is a virtual office.</h5>
                    <p>Looking at the current work from home scenarios, we are quite habituated with the word virtual. So, the virtual office spaces are assisting employees and entrepreneurs to manage all of their physical works via the internet, when they all are working remotely. There are loads of benefits of having virtual office spaces for your business, where in your absence most of your business deeds are handled carefully with no physical intervention.</p>
                    <h5>Increased productivity</h5>
                    <p>Virtual office spaces cut down on the constant monitoring and checking of employees that usually happens in a normal office. With that being cut there is more scope of time, enhanced productivity, and in short, getting more work done. Virtual <a class="internallinks" href="/corporate-office-spaces-in-hitech-city">office spaces</a> also give you the chance to find the right mindset to get going on your tasks. With Corpwork's virtual space, be your own boss by getting things done quickly and in the right way!</p>
                    <h5>More Flexibility</h5>
                    <p>Virtual working and virtual office spaces are the new trends of working that fit with the changing time and needs. Adapting to virtual working and absorbing the pros of it with Corpwork's virtual office space working is the smartest thing working individuals can do. The virtual office space gives the liberty and flexibility to define the pace of your work. Schedule your work timetable, set your focus times according to your mindset, and create your office space with Corpworks virtual office spaces</p>
                    <h5>Streamlined office</h5>
                    <p>Work from home, work from anywhere might be overwhelming at times. It often attracts distraction, loss of focus, etc. Having a working system in place as Corpwork's virtual office spaces sorts out everything. Corpwork's virtual office spaces easily streamline your office space and tasks to not miss out on any of your goals.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title"><a class="internallinks" href="/coworking-spaces-in-kphb">Our Place</a></span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
