<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Corporate Office Spaces</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="img1">
                        <img src="assets/img/space/corporate1.jpg" alt="image">
                    </div>

                    <div class="img2">
                        <img src="assets/img/space/corporate2.jpg" alt="image">
                    </div>
                  

                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Corporate Office Spaces</h1></b><br><br>
                    <h5>Bespoke Office is not a bad idea!</h5>
                    <p>Get rid of the whole process of renting a whole new place and designing everything on your own and replace it with readymade office spaces, where you can just walk-in and get started with your businesslike ideas. How does it sound? Bringing on the customized offices fulfilling all your needs, we added on a lot of plug and play services ready to click your attention.</p>
                    <p></p>
                    <div class="btn-box">
                        <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                         Schedule A Visit
                        </button>                           
                        </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <h2>Now sharing office space in Hyderabad is a painless experience!</h2>
                    <p>Acquiring <a class="internallinks" href="/coworking-spaces">coworking office spaces</a> in Hyderabad is a necessary errand that adds to the already existing business or office tasks. Corpwork is the one stop-shop to decide on your coworking office spaces or shared office space in Hyderabad. With Corpwork choose the right location or space as per your team size, office structure  and requirement with just a few quick steps.</p>
                    <h5>Shared office spaces for overall growth</h5>
                    <p>Learn and grow your business with inspiring <a class="internallinks" href="/coworking-spaces-in-kphb">shared office spaces</a> that fit every business model.  Bring in the new aesthetic to your office with innovative desks and office rooms designed to inspire and motivate work. Give an overall growth and personality to your corporate business structure with the shared office spaces of corpwork.
                    </p>
                    <h5>Hassle free coworking office spaces 
                    </h5>
                    <p>Get a seamless office experience in the best coworking office spaces of Hyderabad from Corpwork. Have a pleasant and an easy coworking office space setup with the fully furnished, preinstalled amenities for your office. Corpwork facilitates all your daily operational office activities by giving you the professional coworking space experience!
                    </p>
                    <h5>Smart spaces for your smart team
                    </h5>
                    <p>Shared office spaces are for today’s and tomorrow’s businesses. Move into Corpwork’s sharing office space today, for a professional and smart outlook to your entire business outcome.All the Corpwork’s office setup comes with all the office essentials like high end tech, continuous wifi, fully furnished spaces, conference and <a class="internallinks" href="/meeting-rooms">meeting rooms</a> and much more.Now make your desk and office space a smart setup for a fast pacing team like yours!</p>
                    <h5>Functional spaces with design</h5>
                    <p>Shush away the Monday blues with peaceful yet professional coworking and shared spaces for your office.Get a picturesque and aesthetic office space, a desk at your most inspirational spot and with all the pre installed office amenities. Corpwork provides beautiful tech enabled spaces for your smooth work experience. Now don’ t just work like a basic corporate but work in style and design with the fully functional spaces of Corpwork!</p>

                </div>
            </div>
        </div>
    </div>
</section>        
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
