import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  collaborations:String="collaborations";
  ourmission:String="ourmission";
  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      this.titleService.setTitle("About us | Coworking space in Hyderabad");
      this.metaTagService.updateTag({name:'description',content:'theCorpWork is a professional high-quality shared office with a mission to combine modern coworking spaces and an emphasis on  a vibrant community.'});
      this.metaTagService.updateTag({ name: 'keywords', content: 'business centre community, coworking spaces' });
      this.metaTagService.addTags([
        // {name:'description',content:'theCorpWork is a professional high-quality shared office with a mission to combine modern coworking spaces and an emphasis on  a vibrant community.'},
        // { name: 'keywords', content: 'business centre community, coworking spaces' },
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
     }

    ngOnInit() {
     
        
    }

}
