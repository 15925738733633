
        <!-- Start Page Title Area -->
        <div class="page-title-area item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Admin</h2>
                            <ul>
                                <li><a href="/">Admin</a></li>
                                <li>Login</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-12">
                       <!--login popup start-->
                       <!-- SEARCH -->
<div class="admin_block ser-values1">

    <div class="container">
        <div class="search_section">
            <div class="admin_login">
                <div class="login_block">
                    <div class="login-header">
                        <h3>Sign In</h3>
                        <!--<div class="social_icon">
										<span><i class="fa fa-facebook-square" aria-hidden="true"></i></span>
										<span><i class="fa fa-twitter-square" aria-hidden="true"></i></span>
										<span><i class="fa fa-linkedin-square" aria-hidden="true"></i></span>
										<span><i class="fa fa-skype" aria-hidden="true"></i></span>
								</div>-->
                    </div>
                    <div class="login_form">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="input-group form-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" formControlName="username" class="form-control" placeholder="username">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required" class="validation">Username is required</div>
                                </div>
                            </div>
                            <div class="input-group form-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span>
                                </div>
                                <input type="password" formControlName="password" class="form-control" placeholder="password">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required" class="validation">Password is required</div>
                                </div>
                            </div>
                            <!--<div class="row align-items-center remember">
						<input type="checkbox">Remember Me
                    </div>-->
                            <div *ngIf="iserror" class="alert alert-danger">{{errorMessage}}</div>
                            <div class="form-group">
                                <input type="submit" [disabled]="loading" value="Login" class="btn float-right login_btn">
                            </div>
                        </form>

                    </div>
                    <!--<div class="login-footer">
                        <div class="links">
					Don't have an account?<a href="#">Sign Up</a>
				</div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>

</div>
                        <!--loginpopup end-->
                    </div>

                    
                </div>
            </div>
        </section>
        