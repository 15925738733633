<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Meeting Rooms</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="img1">
                        <img src="assets/img/space/meetingroom1.jpg" alt="image">
                    </div>

                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Meeting Rooms</h1></b><br><br>
                    <h5>Let’s make meetings more compelling.</h5>
                    <p>This is the high time where we got to be a lot smarter than we can be viewing the current business scenario, impacted by the unpredictable pandemic. Instead of renting a whole big space in a hotel to accommodate a meeting, booking meeting rooms through  <a class="internallinks" href="/coworking-spaces">coworking spaces</a> sounds more reasonable and less pricey. </p>
                    <p></p>
                    <div class="btn-box">
                        <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                         Schedule A Visit
                        </button>                           
                        </div>
                </div>
            </div>
          
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100" id="aboutarea">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <p>Meetings are an essential part of businesses and enterprise operations. They hold their importance regardless of the kind or size of business. From small to medium scale businesses to large enterprises to startups and freelancers, meetings are an inevitable part of every business and corporate life.</p>
                    <p>Corpwork too believes in the importance of meetings and by that provides the best meeting rooms for rent in Hyderabad, Hitech city where the need of meeting rooms is to the core now!</p>
                    <h5>Let’s make meetings more compelling.</h5>
                    <p>This is the high time where we got to be a lot smarter than we can be viewing the current business scenario, impacted by the unpredictable pandemic. Instead of renting a whole big space in a hotel to accommodate a meeting, booking meeting rooms through coworking spaces sounds more reasonable and less pricey.</p>
                    <h5>Enhanced Productivity</h5>
                    <p>Environment plays a crucial role in productivity levels. And the right meeting room with the correct ambiance shows team productivity to 4X. Calm and peaceful environments are always a good place to create new ideas, brainstorm, involve in insightful team discussions, etc. By having a <a class="internallinks" href="/dedicated-desks">dedicated meeting room</a> with all the amenities you and your team can certainly boost your team discussions.<a class="internallinks" href="/about"> Corpwork</a> facilitates such meeting rooms for rent in Hyderabad Hitech city that bring out the best in your team meetings.</p>
                    <h5>Meet professionally and in style</h5>
                    <p>Meeting rooms bring the aura of seriousness the -"down to business" attitude into the team and the rooms. Every meeting, conference, a discussion held in the meeting room goes smoothly and professionally thereby giving 100 percent results. Therefore it is always advisable for teams and businesses to utilize the meeting rooms to their full potential. With Corpwork, you can easily get meeting rooms for rent to bring both style and professionalism to the table!</p>
                    <h5>Privacy and Confidentiality</h5>
                    <p>Every business holds private and confidential information related to their businesses at some point. This information may be in the form of strategy plans, partnership discussions, etc that can not be discussed out in the open and in the other parts of the office. Utilize the meeting rooms of Corpwork to discuss in confidence and a secure environment the private and confidential discussions that you would like to remain in a closed room!</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title"><a class="internallinks" href="/coworking-spaces-in-kphb">Our Place</a></span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
