import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-corporate-office-spaces-in-hitech-city',
  templateUrl: './corporate-office-spaces-in-hitech-city.component.html',
  styleUrls: ['./corporate-office-spaces-in-hitech-city.component.css','../../../assets/css/mystyles.css','../../../assets/css/responsive.css']
})
export class CorporateOfficeSpacesInHitechCityComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { this.titleService.setTitle("Corporate Office Space For Rent in Hitech City Hyderabad | Business Space");
    this.metaTagService.updateTag({name:'description',content:'Get a office desk for rent in Hitec city, Business Centre, plug & play office spaces, Virtual Office & Meeting Rooms, Shared Office space in Hitec City Hyderabad. theCORPWORK'});   
this.metaTagService.updateTag({ name: 'keywords', content: 'Corporate Office spaces in hitec city, Virtual office space in hyderabad' });
      
    this.metaTagService.addTags([
      // {name:'description',content:''},
      // { name: 'keywords', content: ''},
      { name: 'robots', content: 'index, follow' },
      
      { charset: 'UTF-8' }
    ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);}

    ngOnInit() {
      
        
    }

}
