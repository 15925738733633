import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
meetingBookings : any;
contacts : any;
  constructor(private clients : ClientsService) { }

 async ngOnInit(){
    console.log("welcome to dashboard");
   await this.clients.getMeetingRoomRequests().pipe(map(res => {
      console.log(res);
      this.meetingBookings = res;
    })).toPromise();
   await this.clients.getContactRequests().pipe(map(res => {
      console.log(res);
      this.contacts = res;
    })).toPromise();
  }

}
