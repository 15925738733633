<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>Coworking Spaces</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->


<!-- Start Space Area -->
<section class="about-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">       
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="img1">
                        <img src="assets/img/space/coworking1.jpg" alt="image">
                    </div>

                    <div class="img2">
                        <img src="assets/img/space/coworking2.jpg" alt="image">
                    </div>

                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <b><h1 class="sub-title" style="font-size: 35px">Coworking Spaces</h1></b><br><br>
                    <h5>Celebrate your moment of solitude.</h5>
                    <p>Loitering in cafes in search of quality time and space to discuss an idea to market a start-up or got a handful of hours to complete a freelance project? Here we have the best coworking spaces with all the amenities that will give you a mixed feel of a cool cafe and a target-driven office altogether. Coworking space is not a cliche workspace but it is all about an atmosphere that takes you away from the crowd you are avoiding. It gives you the utmost space to explore yourselves and work on your engaging ideas.</p>
                    <p></p>
                    <div class="btn-box">
                        <button type="button" class="default-btn" style="font-size:14px;padding:7px" data-toggle="modal" data-target="#scheduletour">
                         Schedule A Visit
                        </button>                           
                        </div>
                </div>
            </div>
          
        </div>
    </div>
</section>
<!-- End Space Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-text">
                    <h5>Celebrate your moment of solitude.</h5>
                    <p>Loitering in cafes in search of quality time and space to discuss an idea to market a start-up or got a handful of hours to complete a freelance project? Here we have the best coworking spaces with all the amenities that will give you a mixed feel of a cool cafe and a target-driven office altogether. Coworking space is not a cliche workspace but it is all about an atmosphere that takes you away from the crowd you are avoiding. It gives you the utmost space to explore yourselves and work on your engaging ideas.</p>
                    <h5>Be a champion in work-life balance.</h5>
                    <p>Attain the perfect well balanced work-life that we all desire with the shared coworking spaces of Corpwork. The coworking spaces open the possibilities to a wider scope of working while being connected to personal and casual life. Corpwork has smartly placed itself in great locations like Hitech city surrounded by supermarkets, restaurants, and your fellow  <a class="internallinks" href="/corporate-office-spaces"> corporate offices.</a> Now reach productivity and peace with work-life balance.</p>
                    <h5>Flexibility and price value</h5>
                    <p>Establishing a new business, working for a startup, or just being a freelancer demands a private space with a cost-efficient solution. Corpwork, coworking spaces give flexibility allowing you to pay only for the space you want and whenever you want. Eliminate the high-cost end office options and go for the cost-saving coworking spaces. Corpwork <a class="internallinks" href="/corporate-office-spaces-in-hitech-city">coworking spaces in Hyderabad</a>, Hitech city are fully furnished with high-end tech needs, fast wifi, private desks, meeting, <a class="internallinks" href="/meeting-rooms">conference rooms</a>, everything that you require in your office space!</p>
                    <h5>Boost your productivity</h5>
                    <p>Workspace plays an important role in setting up the spirit of work and productivity. Coworking spaces such as Corpwork provide the space to accommodate all styles and moods of business. Be it your Monday morning hurdle, your Friday reporting, or impromptu meetings during the day, Corpwork gives it all. See a substantial increase in your and your team's productivity when they start working in inspirational and professional coworking spaces. Now, solidify your work mode and productivity pace with Corpwork coworking spaces! </p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Place Area -->
<section class="place-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="place-content">
                    <span class="sub-title">Our Place</span>
                    <h2>Building & Area</h2>
                    <p> </p>

                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-parking"></i>
                            </div>
                            <span>Parking Area</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-breakfast"></i>
                            </div>
                            <span>Restaurants</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-shop"></i>
                            </div>
                            <span>Supermarket</span>
                    
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-wifi"></i>
                            </div>
                            <span>High Speed Wifi</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-taxi"></i>
                            </div>
                            <span>Car Sharing</span>
                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Great Location</span>
                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <ngb-carousel #carousel [interval]="3000" style="display: initial;"  >
               
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img2.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        <ng-template ngbSlide>
                    
                                            <div class="picsum-img-wrapper">
                                            <img src="assets/img/place-img3.jpg" alt="image">
                                        </div>
                                        </ng-template>
                                        </ngb-carousel>
            </div>
        </div>
    </div>
</section>
<!-- End Place Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container text-center items-align-center" id="id2">
        <div class="partner-title">
            <h2>Collaborations</h2>
        </div>

        <ngb-carousel #carousel [interval]="1000" id="collabrtns">
            
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/1.png" alt="Angular Carousel 1">
                 
              </div>     
                     
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="/assets/img/partner-image/2.png" alt="Angular Carousel 2">
              </div>
               
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/3.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/4.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                  <img src="/assets/img/partner-image/5.png" alt="Angular Carousel 2">
                </div>
                 
              </ng-template>
          </ngb-carousel>

    </div>
</section>
<!-- End Partner Area -->
