import { Component, OnInit } from '@angular/core'; 
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) {
      this.titleService.setTitle("Office space for rent |Plug & play office |Shared office space");
      this.metaTagService.updateTag({name:'description',content:'Shared office spaces for your comfort workspace, Occupy your Flexible plug & play  Office Spaces for rent at theCORPWORK - Hyderabad get all your required emenities at your work zone desk'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'plug and play office for rent in hyderabad, shared workspace' });
            
      this.metaTagService.addTags([
        // {name:'description',content:'"'},
        // { name: 'keywords', content: ''},
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
     }

  ngOnInit(): void {
    
   
  }
   
   
   
}
