import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor( private titleService: Title,
    private metaTagService: Meta,@Inject(DOCUMENT) private doc) { 
      this.titleService.setTitle("Best Coworking Space |Working Spaces | Office Space | Shared Office Spaces On Rent");
      this.metaTagService.updateTag({name:'description',content:'Book your fullly serviced office space in Hyderabad.virtual offices, Corporate Office, available at rent for startups, freelancers & business owners'});   
      this.metaTagService.updateTag({ name: 'keywords', content: 'CoWorking Space in Hyderabad. Shared Office Space for Startups in Hyderabad, StartUp Space Hyderabad, fully Serviced Office for freelancers and business owners' });
      this.metaTagService.addTags([
        // {name:'description',content:''},
        // { name: 'keywords', content: '' },
        { name: 'robots', content: 'index, follow' },
        
        { charset: 'UTF-8' }
      ]);   
let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }

    ngOnInit() {
    
        
    }

}
